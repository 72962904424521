import moment from "moment";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Button, Card, CardBody, CardTitle, Col, Input, Row } from "reactstrap";
import { registerLocale } from "react-datepicker";

import api from "../api";
import { getDate2FromMoment, userInfo } from "../utils";
import { FaCalendarAlt } from "react-icons/fa";
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
const Home = (props) => {
  const [fee, setFee] = useState(0);
  const [commissionFee, setCommissionFee] = useState(0);
  const [happenedCount, setHappenedCount] = useState(0);
  const [notHappenedCount, setNotHappenedCount] = useState(0);
  const [cancelledCount, setCancelledCount] = useState(0);
  const [timeoutedCount, setTimeoutedCount] = useState(0);
  const [companyId, setCompanyId] = useState();
  const [companyBranchId, setCompanyBranchId] = useState();
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [startDate, setStartDate] = useState(moment().set("D", 1));
  const [endDate, setEndDate] = useState(moment());
  useEffect(() => {
    if (!props.companyId) return setCompanyId();
    setCompanyId(parseInt(props.companyId));
  }, [props.companyId]);
  useEffect(() => {
    if (!props.companyBranchId) return setCompanyBranchId();
    setCompanyBranchId(parseInt(props.companyBranchId));
  }, [props.companyBranchId]);
  useEffect(() => {
    const fetchData = async () => {
      const companies = await api.admin.company.get();
      setCompanies(companies);
    };
    fetchData();
    const startDate = moment();
    startDate.set("D", 1);
    setStartDate(startDate);
    const endDate = moment();
    setEndDate(endDate);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      if (!companyId) return;
      if (userInfo().type === 1) {
        const branches = await api.admin.companyBranches(companyId);
        setBranches(branches);
        setCompanyBranchId();
      }
      if (userInfo().type === 2) {
        const branches = await api.company.branches(companyId);
        setBranches(branches);
        setCompanyBranchId();
      }
    };
    fetchData();
  }, [companyId]);
  useEffect(() => {
    getStatistics();
  }, [companyId, companyBranchId, startDate, endDate]);
  const getStatistics = async () => {
    const startDatee = getDate2FromMoment(startDate);
    const endDatee = getDate2FromMoment(endDate);
    if (userInfo().type === 1) {
      const feeData = await api.admin.feeCalculator(
        startDatee,
        endDatee,
        undefined,
        undefined,
        companyId,
        companyBranchId
      );
      const happenedData = await api.admin.feeCalculator(
        startDatee,
        endDatee,
        undefined,
        "happened",
        companyId,
        companyBranchId
      );
      const notHappenedData = await api.admin.feeCalculator(
        startDatee,
        endDatee,
        undefined,
        "notHappened",
        companyId,
        companyBranchId
      );
      const timeouted = await api.admin.feeCalculator(
        startDatee,
        endDatee,
        undefined,
        "timeouted",
        companyId,
        companyBranchId
      );
      const cancelledData = await api.admin.feeCalculator(
        startDatee,
        endDatee,
        undefined,
        "cancelled",
        companyId,
        companyBranchId
      );
      setFee(feeData.TotalFee);
      setCommissionFee(feeData.TotalCommissionFee);
      setHappenedCount(happenedData.Count);
      setNotHappenedCount(notHappenedData.Count);
      setCancelledCount(cancelledData.Count);
      setTimeoutedCount(timeouted.Count);
    }
    if (userInfo().type === 2) {
      const feeData = await api.company.feeCalculator(
        startDatee,
        endDatee,
        undefined,
        undefined,
        companyBranchId
      );
      const happenedData = await api.company.feeCalculator(
        startDatee,
        endDatee,
        undefined,
        "happened",
        companyBranchId
      );
      const notHappenedData = await api.company.feeCalculator(
        startDatee,
        endDatee,
        undefined,
        "notHappened",
        companyBranchId
      );
      const timeouted = await api.company.feeCalculator(
        startDatee,
        endDatee,
        undefined,
        "timeouted",
        companyBranchId
      );
      const cancelledData = await api.company.feeCalculator(
        startDatee,
        endDatee,
        undefined,
        "cancelled",
        companyBranchId
      );
      setFee(feeData.TotalFee);
      setCommissionFee(feeData.TotalCommissionFee);
      setHappenedCount(happenedData.Count);
      setNotHappenedCount(notHappenedData.Count);
      setCancelledCount(cancelledData.Count);
      setTimeoutedCount(timeouted.Count);
    }
    if (userInfo().type === 3) {
      const feeData = await api.companyBranch.feeCalculator(
        startDatee,
        endDatee,
        undefined,
        undefined
      );
      const happenedData = await api.companyBranch.feeCalculator(
        startDatee,
        endDatee,
        undefined,
        "happened"
      );
      const notHappenedData = await api.companyBranch.feeCalculator(
        startDatee,
        endDatee,
        undefined,
        "notHappened"
      );
      const timeouted = await api.companyBranch.feeCalculator(
        startDatee,
        endDatee,
        undefined,
        "timeouted"
      );
      const cancelledData = await api.companyBranch.feeCalculator(
        startDatee,
        endDatee,
        undefined,
        "cancelled"
      );
      setFee(feeData.TotalFee);
      setCommissionFee(feeData.TotalCommissionFee);
      setHappenedCount(happenedData.Count);
      setNotHappenedCount(notHappenedData.Count);
      setCancelledCount(cancelledData.Count);
      setTimeoutedCount(timeouted.Count);
    }
  };
  return (
    <Col style={{ padding: "10px" }}>
      {userInfo().type === 1 && (
        <Row>
          Firma :{" "}
          <Input
            type="select"
            value={companyId}
            onChange={(e) =>
              e.target.value !== "" && setCompanyId(parseInt(e.target.value))
            }
          >
            <option>Firma Seçiniz</option>
            {companies.map((item, key) => (
              <option key={key} value={item.Id}>
                {item.Name}
              </option>
            ))}
          </Input>
        </Row>
      )}
      {[1, 2].includes(userInfo().type) && (
        <Row>
          Bayi :
          <Input
            type="select"
            value={companyBranchId}
            onChange={(e) =>
              e.target.value !== "" &&
              setCompanyBranchId(parseInt(e.target.value))
            }
          >
            <option>Bayi Seçiniz</option>
            {branches.map((item, key) => (
              <option key={key} value={item.Id}>
                {item.BranchName}
              </option>
            ))}
          </Input>
        </Row>
      )}
      <Row style={{ marginTop: "10px" }}>
        <Col md={3}>Başlangıç Tarihi: </Col>
        <Col md={3}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "start",
            }}
          >
            <ReactDatePicker
              locale="tr"
              onChange={(e) => setStartDate(moment(e))}
              selected={startDate.toDate()}
              id="home-start-date"
              icon={<FaCalendarAlt />}
            />
            <label htmlFor="home-start-date">
              <FaCalendarAlt
                style={{
                  marginTop: "5px",
                  marginLeft: "3px",
                  cursor: "pointer",
                }}
              />
            </label>
          </div>
        </Col>
        <Col md={3}>Bitiş Tarihi: </Col>
        <Col md={3}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "start",
            }}
          >
            <ReactDatePicker
              locale="tr"
              onChange={(e) => setEndDate(moment(e))}
              selected={endDate.toDate()}
              id="home-end-date"
            />
            <label htmlFor="home-end-date">
              <FaCalendarAlt
                style={{
                  marginTop: "5px",
                  marginLeft: "3px",
                  cursor: "pointer",
                }}
              />
            </label>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Button onClick={() => getStatistics()} color="primary">
          Filtrele
        </Button>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Row style={{ marginTop: "10px" }}>
          <Card color="primary">
            <CardTitle tag="h5">Ödenecek Toplam</CardTitle>
            <CardBody>{fee} TL</CardBody>
          </Card>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Card color="secondary">
            <CardTitle tag="h5">Komisyon Miktarı</CardTitle>
            <CardBody>{commissionFee} TL</CardBody>
          </Card>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Card color="success">
            <CardTitle tag="h5">Gerçekleşen Sipariş Sayısı</CardTitle>
            <CardBody>{happenedCount}</CardBody>
          </Card>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Card color="info">
            <CardTitle tag="h5">Henüz Gerçekleşmeyen Sipariş Sayısı</CardTitle>
            <CardBody>{notHappenedCount}</CardBody>
          </Card>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Card color="danger">
            <CardTitle tag="h5">Süresi Geçmiş Sipariş Sayısı</CardTitle>
            <CardBody>{timeoutedCount}</CardBody>
          </Card>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Card color="warning">
            <CardTitle tag="h5">İptal Edilen Sipariş Sayısı</CardTitle>
            <CardBody>{cancelledCount}</CardBody>
          </Card>
        </Row>
      </Row>
    </Col>
  );
};
export default Home;
