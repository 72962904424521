import { useEffect, useState } from "react";
import api, { apiUrl } from "../api";
import AddAndEditTable from "../Components/AddAndEditTable";
const CompanyProductKitchenCategory = (props = { sub: false }) => {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    getCategoriesList(props.sub);
  }, [props.sub]);
  const getCategoriesList = async (sub) => {
    const categories = await api.admin.companyProductKitchenCategory.get(sub);
    !categories.error &&
      setCategories(
        categories.map((item) => ({
          ...item,
          ImageUrl:
            apiUrl +
            "/static/companyProductKitchenCategoryImages/" +
            item.Id +
            ".jpg",
          Main: item["Main.Name"],
        }))
      );
  };
  const createCategory = async (row) => {
    row.IsSub = props.sub ? true : false;
    const create = await api.admin.companyProductKitchenCategory.create(row);
    if (!create.error) {
      getCategoriesList(props.sub);
      return alert("Kategori Oluşturuldu.");
    }
    if (create.message) return alert(create.message);
    alert("Kategori oluşturulamadı.");
  };
  const updateCategory = async (id, row) => {
    delete row.subs;
    const update = await api.admin.companyProductKitchenCategory.update(
      id,
      row
    );
    if (!update.error) {
      getCategoriesList(props.sub);
      return alert("Kategori Güncellendi.");
    }
    if (update.message) return alert(update.message);
    alert("Kategori Güncellenemedi.");
  };
  const deleteCategory = async (id) => {
    const confirmm = window.confirm("Silmek istediğinize emin misiniz?");
    if (confirmm) {
      const del = await api.admin.companyProductKitchenCategory.delete(id);
      if (!del.error) {
        getCategoriesList(props.sub);
        return alert("Kategori Silindi.");
      }
      alert("Kategori Silinemedi.");
    }
  };
  return (
    <AddAndEditTable
      type={`${props.sub ? "Alt" : "Ana"} Ürün Tipi Kategorileri`}
      rows={categories}
      cols={[
        {
          name: "Name",
          type: "text",
          label: `${props.sub ? "Alt" : "Ana"} Ürün Tipi Kategori Adı`,
        },
        {
          name: "ImageUrl",
          type: "file",
          label: "Kategori Resmi",
          fileType: "image",
          width: "40px",
          height: "40px",
        },
      ]}
      create={(row) => createCategory(row)}
      update={(id, row) => updateCategory(id, row)}
      deletee={(id) => deleteCategory(id)}
    />
  );
};
export default CompanyProductKitchenCategory;
