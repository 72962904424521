import { useState } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import api from "../api";

const LoadXml = () => {
  const [fileContents, setFileContents] = useState("");
  const send = async () => {
    if (!fileContents) return alert("Dosya seçiniz!");
    const add = await api.company.addProductFromXml(fileContents);
    if (!add.error) {
      return alert("Ürünler başarıyla kaydedildi");
    } else if (add.error && add.message) return alert(add.message);
    alert("Hatalı XML");
  };
  return (
    <Col>
      <Row>
        Xml dosyası :{" "}
        <Input
          type="file"
          accept="application/xml"
          onChange={(e) => {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.addEventListener(
              "load",
              () => {
                setFileContents(reader.result);
              },
              false
            );
            if (file) {
              reader.readAsText(file);
            }
          }}
        />
      </Row>
      <Row>
        <Button color="success" onClick={send}>
          Gönder
        </Button>
      </Row>
    </Col>
  );
};
export default LoadXml;
