import { useEffect, useState } from "react";
import api from "../api";
import { Button, Col, Input, Row, Table } from "reactstrap";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { FaArrowDown, FaCalendarAlt } from "react-icons/fa";

const paymentStatuses = [
  {
    value: 0,
    label: "Ödeme tamamlanmadı",
  },
  {
    value: 1,
    label: "Ödendi",
  },
  {
    value: 2,
    label: "Ödeme hatası",
  },
];

const AllOrders = () => {
  const [orders, setOrders] = useState([]);
  const [filtered, setFiltered] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const orders = await api.admin.allOrders();
      setFiltered(orders.result);
      setOrders(orders.result);
    };
    fetchData();
  }, []);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [paymentStatus, setPaymentStatus] = useState(-1);
  const filter = () => {
    const filtered = orders.filter((item) => {
      if (name) {
        let control = false;
        if (item.Sender?.NameSurname?.startsWith(name)) {
          control = true;
        } else if (item?.Receiver?.NameSurname?.startsWith(name)) {
          control = true;
        }
        if (!control) return false;
      }
      if (startDate)
        if (!moment(item.createdAt).isAfter(startDate)) return false;

      if (endDate) if (!moment(item.createdAt).isBefore(endDate)) return false;

      if (paymentStatus !== -1)
        if (item.IsPayyed !== paymentStatus) return false;
      return true;
    });
    setFiltered(filtered);
  };
  const resetFilter = () => {
    setName("");
    setStartDate();
    setEndDate();
    setPaymentStatus(-1);
    setFiltered(orders);
  };
  return (
    <div>
      <h1>Tüm Siparişler</h1>
      <Col md={6}>
        <h3>Filtrele</h3>
        <Row>
          Ad {"   "}:
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ width: "300px" }}
            placeholder="Ad girin"
          />
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col md={1}>Başlangıç Tarihi: </Col>
          <Col md={3}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "start",
              }}
            >
              <ReactDatePicker
                locale="tr"
                onChange={(e) => setStartDate(moment(e))}
                selected={startDate?.toDate()}
                id="all-orders-start-date"
                icon={<FaCalendarAlt />}
              />
              <label htmlFor="all-orders-start-date">
                <FaCalendarAlt
                  style={{
                    marginTop: "5px",
                    marginLeft: "3px",
                    cursor: "pointer",
                  }}
                />
              </label>
            </div>
          </Col>
          <Col md={1}>Bitiş Tarihi: </Col>
          <Col md={3}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "start",
              }}
            >
              <ReactDatePicker
                locale="tr"
                onChange={(e) => setEndDate(moment(e))}
                selected={endDate?.toDate()}
                id="all-orders-end-date"
              />
              <label htmlFor="all-orders-end-date">
                <FaCalendarAlt
                  style={{
                    marginTop: "5px",
                    marginLeft: "3px",
                    cursor: "pointer",
                  }}
                />
              </label>
            </div>
          </Col>
          <Col md={1}>Ödeme Durumu :</Col>
          <Col md={3}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "start",
              }}
            >
              <Input
                type="select"
                value={paymentStatus}
                onChange={(e) =>
                  e.target.value !== "-1" &&
                  e.target.value !== -1 &&
                  setPaymentStatus(parseInt(e.target.value))
                }
                id="payment-status-input"
              >
                <option>Ödeme durumu seçiniz</option>
                {paymentStatuses.map((item, key) => (
                  <option key={key} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Input>
              <label for="payment-status-input">
                <FaArrowDown
                  style={{
                    marginTop: "5px",
                    marginLeft: "3px",
                    cursor: "pointer",
                  }}
                />
              </label>
            </div>
          </Col>
        </Row>
        <Col style={{ marginTop: "20px" }}>
          <Button onClick={filter}>Filtrele</Button>
          <Button onClick={resetFilter} style={{ marginLeft: "20px" }}>
            Sıfırla
          </Button>
        </Col>
      </Col>
      <Table border={1} bordered>
        <thead>
          <tr>
            <td>Id</td>
            <td>Tarih</td>
            <td>Sipariş Kodu</td>
            <td>Manuel Kod</td>
            <td>Gönderen</td>
            <td>Alan</td>
            <td>Şirket</td>
            <td>Bayi</td>
            <td>Ürün - Seçenek - Sayı - Fiyat</td>
            <td>Toplam Fiyat</td>
            <td>Komisyon</td>
            <td>Ödenme Durumu</td>
            <td>Kampanya</td>
            <td>Promosyon</td>
          </tr>
        </thead>
        <tbody>
          {filtered.map((item, key) => (
            <tr
              key={key}
              style={{ background: key % 2 === 0 ? "gray" : "white" }}
            >
              <td>{item.Id}</td>
              <td>{moment(item.createdAt).format("DD.MM.YYYY HH:mm:SS")}</td>
              <td>{item.OrderCode}</td>
              <td>{item.ManualCode}</td>
              <td>{item.Sender.NameSurname}</td>
              <td>
                {item.Receiver
                  ? item.Receiver.NameSurname
                  : item.ReceiverPhoneNumber}
              </td>
              <td>{item?.Company?.Name}</td>
              <td>{item?.CompanyBranch?.BranchName}</td>
              <td>
                <ul>
                  {item?.CompanyBranchOrderProducts?.map((item2, key2) => (
                    <li key={key2}>
                      {`${item2?.CompanyProduct?.Name} - ${
                        item2?.CompanyProductOption?.Name
                      } - ${item2.Number} adet - ${
                        item2.Price * item2.Number
                      } TL`}
                    </li>
                  ))}
                </ul>
              </td>
              <td>{item.CartPrice} TL</td>
              <td>{item.CommissionPrice} TL</td>
              <td>
                {item.IsPayyed === 0
                  ? "Ödeme tamamlanmadı"
                  : item.IsPayyed === 1
                  ? "Ödendi"
                  : "Ödeme hatası"}
              </td>
              <td>{item?.Campaign?.Name}</td>
              <td>{item.PromotionCode}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AllOrders;
