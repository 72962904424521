import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import api, { apiUrl } from "../api";

const ProductRequests = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  useEffect(() => getProducts(), []);
  const responseProduct = async (approved) => {
    const Id = selectedProduct.Id;
    const IsApproved = approved ? true : false;
    const response = await api.admin.companyProduct.requestUpdate(Id, {
      IsApproved,
    });
    if (!response.error) {
      alert("Başarıyla " + (approved ? "kabul edildi" : "reddedildi"));
      setSelectedProduct();
      getProducts();
    } else
      alert(
        "İşlem başarısız oldu.(Hatalar :" +
          response.errors?.map((item) => item.msg).join(",") +
          ")"
      );
  };
  const getProducts = async () => {
    const products = await api.admin.companyProduct.requests();
    setProducts(
      products.map((item) => ({
        ...item,
        ImageUrl: apiUrl + "/static/companyProductImages/" + item.Id + ".jpg",
        CompanyProductCategoryName: item.CompanyProductCategory.Name,
        CompanyProductKitchenCategoryName:
          item?.CompanyProductKitchenTypeCategory?.Name,
        CompanyProductOptions: (item.CompanyProductOptions
          ? item.CompanyProductOptions
          : []
        ).map((item2) => ({
          ...item2,
          ImageUrl:
            apiUrl + "/static/companyProductOptionImages/" + item2.Id + ".jpg",
        })),
      }))
    );
  };
  const cols = [
    {
      type: "text",
      name: "CompanyProductCategoryName",
      label: "Ürün Menü Kategorisi",
    },
    {
      type: "text",
      name: "CompanyProductKitchenCategoryName",
      label: "Ürün Tipi Kategorisi",
    },
    {
      name: "Name",
      type: "text",
      label: "Ürün Adı",
    },
    {
      name: "SmallName",
      type: "text",
      label: "Ürün Kısa Adı",
    },
    {
      name: "Description",
      type: "text",
      label: "Ürün Açıklaması",
    },
    {
      name: "ProductCode",
      type: "text",
      label: "Ürün Kodu",
    },
    {
      name: "BarcodeCode",
      type: "text",
      label: "Ürün Barkod",
    },
    // {
    //   name: "Price",
    //   type: "floatNumber",
    //   label: "Ürün Fiyatı",
    //   step: 0.01,
    // },
    {
      name: "ImageUrl",
      type: "file",
      label: "Ürün Resmi",
      fileType: "image",
      width: "40px",
      height: "40px",
    },
  ];
  return (
    <Col>
      <Modal
        isOpen={selectedProduct ? true : false}
        backdrop={true}
        toggle={() => setSelectedProduct()}
      >
        <ModalHeader>Ürün Detayları</ModalHeader>
        <ModalBody>
          {selectedProduct && (
            <Row>
              <h2>Şirket : {selectedProduct?.Company.Name}</h2>
            </Row>
          )}
          {selectedProduct &&
            cols.map((item, key) => (
              <Row key={key} style={{ borderBottom: "1px solid gray" }}>
                <Col md={4}>{item.label}</Col>
                <Col md={8}>
                  {" "}
                  :{" "}
                  {item.type !== "file" &&
                    (item.showValue
                      ? selectedProduct[item.showValue]
                      : selectedProduct[item.name])}
                  {item.type === "file" && (
                    <img
                      src={selectedProduct[item.name]}
                      style={{ width: "40px", height: "40px" }}
                    />
                  )}
                </Col>
              </Row>
            ))}
          <Row>
            <h4>Ürün Seçenekleri</h4>
          </Row>
          {selectedProduct &&
            selectedProduct.CompanyProductOptions &&
            selectedProduct.CompanyProductOptions.length > 0 && (
              <Table>
                <thead>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {selectedProduct.CompanyProductOptions.map((item, key) => (
                    <React.Fragment key={key}>
                      <tr>
                        <td>Seçenek Adı</td>
                        <td>
                          <b>{item.Name}</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Bendensin Fiyatı</td>
                        <td>
                          <b>{item.Price}</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Gerçek Fiyat</td>
                        <td>
                          <b>{item.RealPrice}</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Resim</td>
                        <td>
                          <b>
                            <img
                              src={item.ImageUrl}
                              style={{ width: "40px", height: "40px" }}
                            />
                          </b>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            )}
          {!(
            selectedProduct &&
            selectedProduct.CompanyProductOptions &&
            selectedProduct.CompanyProductOptions.length > 0
          ) && (
            <h2 style={{ color: "red" }}>
              {" "}
              Ürün seçeneği bulunmamaktadır. Düzenleme gerekli. Reddedilmeli.{" "}
            </h2>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => responseProduct(true)}>
            Onayla
          </Button>
          <Button color="danger" onClick={() => responseProduct(false)}>
            Reddet
          </Button>
        </ModalFooter>
      </Modal>
      <Table>
        <thead>
          <tr>
            <td>Ürün Adı</td>
            <td>Barkod</td>
            <td>Açıklaması</td>
            <td>Kodu</td>
            <td>Ürün Menü Kategorisi</td>
            <td>Ürün Tipi Kategorisi</td>
            <td>Resim</td>
          </tr>
        </thead>
        <tbody>
          {products.map((item, key) => (
            <tr key={key}>
              <td>{item.Name}</td>
              <td>{item.BarcodeCode}</td>
              <td>{item.Description}</td>
              <td>{item.ProductCode}</td>
              <td>{item?.CompanyProductCategory?.Name}</td>
              <td>{item?.CompanyProductKitchenTypeCategory?.Name}</td>
              <td>
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={item.ImageUrl}
                />
              </td>
              <td>
                <Button
                  color="success"
                  onClick={() => setSelectedProduct(item)}
                >
                  Detaylar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Col>
  );
};
export default ProductRequests;
