import { useEffect, useState } from "react";
import api, { apiUrl } from "../api";
import AddAndEditTable from "../Components/AddAndEditTable";
const CompanyCategory = () => {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    getCategoriesList();
  }, []);
  const getCategoriesList = async () => {
    const categories = await api.admin.companyCategory.get();
    !categories.error &&
      setCategories(
        categories.map((item) => ({
          ...item,
          ImageUrl:
            apiUrl + "/static/companyCategoryImages/" + item.Id + ".jpg",
        }))
      );
  };
  const createCategory = async (row) => {
    const create = await api.admin.companyCategory.create(row);
    if (!create.error) {
      getCategoriesList();
      return alert("Kategori Oluşturuldu.");
    }
    alert("Kategori oluşturulamadı.(Hatalar :"+create.errors?.map(item => item.msg).join(",")+")");
  };
  const updateCategory = async (id, row) => {
    delete row.subs;
    const update = await api.admin.companyCategory.update(id, row);
    if (!update.error) {
      getCategoriesList();
      return alert("Kategori Güncellendi.");
    }
    alert("Kategori Güncellenemedi.(Hatalar :"+update.errors?.map(item => item.msg).join(",")+")");
  };
  const deleteCategory = async (id) => {
    const confirmm = window.confirm("Silmek istediğinize emin misiniz?");
    if (confirmm) {
      const del = await api.admin.companyCategory.delete(id);
      if (!del.error) {
        getCategoriesList();
        return alert("Kategori Silindi.");
      }
      alert("Kategori Silinemedi.");
    }
  };
  return (
    <AddAndEditTable
      type="Firma Kategorileri"
      rows={categories}
      cols={[
        {
          name: "Name",
          type: "text",
          label: "Firma Kategori Adı",
        },
        {
          name: "ImageUrl",
          type: "file",
          label: "Kategori Resmi",
          fileType: "image",
          width: "40px",
          height: "40px",
        },
        // {
        //   type: "text",
        //   name: "description",
        //   label: "Açıklama",
        // },
        // {
        //   type: "file",
        //   name: "mainImage",
        //   fileType: "image",
        //   width: "100px",
        //   height: "100px",
        //   label: "Ana Resim",
        // },
        // {
        //   type: "select",
        //   label: "Alt Kategori",
        //   value: "sub",
        //   values: categories.map((item) => ({
        //     ...item,
        //     label: item.nameWithSpaces,
        //     id: item._id,
        //   })),
        // },
      ]}
      create={(row) => createCategory(row)}
      update={(id, row) => updateCategory(id, row)}
      deletee={(id) => deleteCategory(id)}
    />
  );
};
export default CompanyCategory;
