import { useEffect, useState } from "react";
import api, { apiUrl } from "../api";
import AddAndEditTable from "../Components/AddAndEditTable";
const CompanyBranch = () => {
  const [companies, setCompanies] = useState([]);
  const [companyBranches, setCompanyBranches] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  useEffect(() => {
    getCompanyBranchList();
    getCompanyList();
    getCityList();
  }, []);
  const getCityList = async () => {
    const cities = await api.cities();
    !cities.error && setCities(cities);
  };
  const getDistrictList = async (cityId) => {
    const districts = await api.districts(cityId);
    !districts.error && setDistricts(districts);
  };
  const getCompanyBranchList = async () => {
    const companyBranches = await api.admin.companyBranch.get();
    !companyBranches.error &&
      setCompanyBranches(
        companyBranches.map((item) => ({
          ...item,
          LogoImageUrl: apiUrl + "/static/companyImages/" + item.Id + ".jpg",
        }))
      );
  };
  const getCompanyList = async () => {
    const companies = await api.admin.company.get();
    !companies.error &&
      setCompanies(
        companies.map((item) => ({
          ...item,
          LogoImageUrl: apiUrl + "/static/companyImages/" + item.Id + ".jpg",
        }))
      );
  };
  const createCompanyBranch = async (row) => {
    const create = await api.admin.companyBranch.create(row);
    if (!create.error) {
      getCompanyBranchList();
      return alert("Firma Bayi Oluşturuldu.");
    }
    if (create.error === 22) {
      return alert("Böyle bir kullanıcı bulunuyor");
    }
    alert(
      "Firma Bayi oluşturulamadı.(Hatalar :" +
        create.errors?.map((item) => item.msg).join(",") +
        ")"
    );
  };
  const updateCompanyBranch = async (id, row) => {
    const update = await api.admin.companyBranch.update(id, row);
    if (!update.error) {
      getCompanyBranchList();
      return alert("Firma Bayi Güncellendi.");
    }
    if (update.message) return alert(update.message);
    alert(
      "Firma Bayi Güncellenemedi.(Hatalar :" +
        update.errors?.map((item) => item.msg).join(",") +
        ")"
    );
  };
  const deleteCompanyBranch = async (id) => {
    const confirmm = window.confirm("Silmek istediğinize emin misiniz?");
    if (confirmm) {
      const del = await api.admin.companyBranch.delete(id);
      if (!del.error) {
        getCompanyBranchList();
        return alert("Firma Bayi Silindi.");
      }
      alert("Firma Bayi Silinemedi.");
    }
  };
  return (
    <AddAndEditTable
      type="Firma Bayi"
      rows={companyBranches}
      cols={[
        {
          type: "select",
          label: "Firma",
          value: "CompanyId",
          name: "CompanyId",
          values: companies.map((item) => ({
            ...item,
            label: item.Name,
            id: item.Id,
          })),
          noShow: true,
        },
        {
          name: "BranchName",
          type: "text",
          label: "Bayi Adı",
        },
        {
          name: "RegionCode",
          type: "text",
          label: "Bölge Kodu",
        },

        {
          type: "select",
          label: "Şehir",
          value: "CityId",
          name: "CityId",
          values: cities.map((item) => ({
            ...item,
            label: item.Name,
            id: item.Id,
          })),
          onChange: (cityId) => {
            getDistrictList(cityId);
          },
          noShow: true,
        },
        {
          type: "select",
          label: "İlçe",
          value: "DistrictId",
          name: "DistrictId",
          values: districts.map((item) => ({
            ...item,
            label: item.Name,
            id: item.Id,
          })),
          noShow: true,
        },
        {
          name: "Address",
          type: "text",
          label: "Adres",
        },
        {
          name: "GoogleMapAddressUrl",
          type: "text",
          label: "Google Map adresi",
          noShow: true,
        },
        {
          name: "VergiDaire",
          type: "text",
          label: "Vergi Daire",
        },
        {
          name: "VergiNo",
          type: "text",
          label: "Vergi No",
        },
        {
          name: "Username",
          type: "text",
          label: "Kullanıcı Adı",
          noShow: true,
        },
        {
          name: "Password",
          type: "password",
          label: "Şifre",
          noShow: true,
        },
      ]}
      create={(row) => createCompanyBranch(row)}
      update={(id, row) => updateCompanyBranch(id, row)}
      deletee={(id) => deleteCompanyBranch(id)}
    />
  );
};
export default CompanyBranch;
