import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import api, { apiUrl } from "../api";

const ApplicationRequests = () => {
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(undefined);
  useEffect(() => getApplications(), []);

  const responseApplication = async (approved) => {
    const Id = selectedApplication.Id;
    const IsApproved = approved ? true : false;
    const response = await api.admin.company.requestUpdate(Id, { IsApproved });
    if (!response.error) {
      let message = "Başarıyla " + (approved ? "kabul edildi" : "reddedildi");
      if (response.notify) {
        message +=
          "\n Kullanıcı adı çakışması olduğu için kullanıcı adı değiştirildi. Yeni kullanıcı adı : " +
          response.notify +
          "\n Bildirmek için arayın : " +
          response.phoneNumber;
      }
      alert(message);
      setSelectedApplication();
      getApplications();
    } else alert("İşlem başarısız oldu");
  };
  const getApplications = async () => {
    const applications = await api.admin.company.requests();
    setApplications(
      applications.map((item) => ({
        ...item,
        CityName: item.City?.Name,
        DistrictName: item.District?.Name,
        CompanyCategoryName: item.CompanyCategory?.Name,
        LogoImageUrl: apiUrl + "/static/companyImages/" + item.Id + ".jpg",
      }))
    );
  };
  const cols = [
    {
      name: "Name",
      type: "text",
      label: "Firma Adı",
    },
    {
      name: "SmallName",
      type: "text",
      label: "Kısa Adı",
    },
    {
      name: "Description",
      type: "text",
      label: "Şirket Açıklaması",
    },
    {
      name: "Commission",
      type: "number",
      label: "Komisyon Oranı ( Örnek : %5 )",
    },
    {
      type: "select",
      name: "CompanyCategoryId",
      label: "Firma Kategorisi",
      value: "CompanyCategoryId",
      showValue: "CompanyCategoryName",
      noShow: true,
    },
    {
      type: "select",
      label: "Şehir",
      value: "CityId",
      name: "CityId",
      showValue: "CityName",
      noShow: true,
    },
    {
      type: "select",
      label: "İlçe",
      value: "DistrictId",
      name: "DistrictId",
      showValue: "DistrictName",
      noShow: true,
    },
    {
      name: "VergiDaire",
      type: "text",
      label: "Vergi Daire",
    },
    {
      name: "VergiNo",
      type: "text",
      label: "Vergi No",
    },
    {
      name: "Address",
      type: "text",
      label: "Adres",
    },
    {
      name: "AuthorName",
      type: "text",
      label: "Yetkili Adı Soyadı",
    },
    {
      name: "AuthorRole",
      type: "text",
      label: "Yetkili Rolü",
      noShow: true,
    },
    {
      name: "AuthorMobileNumber",
      type: "text",
      label: "Yetkili Telefon Numarası",
    },
    {
      name: "AuthorMail",
      type: "text",
      label: "Yetkili Mail",
    },
    {
      name: "Username",
      type: "text",
      label: "Kullanıcı Adı",
      noShow: true,
    },
    {
      name: "Password",
      type: "password",
      label: "Şifre",
      noShow: true,
    },
    {
      name: "LogoImageUrl",
      type: "file",
      label: "Firma Logo",
      fileType: "image",
      width: "40px",
      height: "40px",
    },
  ];
  return (
    <Col>
      <Modal
        isOpen={selectedApplication ? true : false}
        backdrop={true}
        toggle={() => setSelectedApplication()}
      >
        <ModalHeader>Firma Detayları</ModalHeader>
        <ModalBody>
          {selectedApplication &&
            cols.map((item, key) => (
              <Row key={key} style={{ borderBottom: "1px solid gray" }}>
                <Col md={4}>{item.label}</Col>
                <Col md={8}>
                  {" "}
                  :{" "}
                  {item.type !== "file" &&
                    (item.showValue
                      ? selectedApplication[item.showValue]
                      : selectedApplication[item.name])}
                  {item.type === "file" && (
                    <img
                      src={selectedApplication[item.name]}
                      style={{ width: "40px", height: "40px" }}
                    />
                  )}
                </Col>
              </Row>
            ))}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => responseApplication(true)}>
            Onayla
          </Button>
          <Button color="danger" onClick={() => responseApplication(false)}>
            Reddet
          </Button>
        </ModalFooter>
      </Modal>
      <Table>
        <thead>
          <tr>
            <td>Firma Adı</td>
            <td>Yetkili Adı</td>
            <td>Yetkili Telefon numarası</td>
            <td>İl</td>
            <td>İlçe</td>
            <td>Detaylar</td>
          </tr>
        </thead>
        <tbody>
          {applications.map((item, key) => (
            <tr key={key}>
              <td>{item.Name}</td>
              <td>{item.AuthorName}</td>
              <td>{item.AuthorMobileNumber}</td>
              <td>{item.CityName}</td>
              <td>{item.DistrictName}</td>
              <td>
                <Button
                  color="success"
                  onClick={() => setSelectedApplication(item)}
                >
                  Detaylar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Col>
  );
};
export default ApplicationRequests;
