import { useEffect, useState } from "react";
import api from "../api";
import { Button, Input } from "reactstrap";

const CompanyProductkitchenCategoryMatcher = () => {
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedCategoryValues, setSelectedCategoryValues] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const mainCategories =
        await api.admin.companyProductKitchenCategory.get();
      const subCategories = await api.admin.companyProductKitchenCategory.get(
        true
      );
      setMainCategories(mainCategories);
      setSubCategories(subCategories);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      if (!selectedCategory) return;
      const getSelectedCategoryValues =
        await api.admin.companyProductKitchenCategory.getMapper(
          selectedCategory
        );
      setSelectedCategoryValues(getSelectedCategoryValues);
    };
    fetchData();
  }, [selectedCategory]);
  const save = async () => {
    try {
      await api.admin.companyProductKitchenCategory.updateMapper(
        selectedCategory,
        selectedCategoryValues
      );
      alert("Güncelleme başarılı");
    } catch (err) {
      alert("Güncellemede bir hata oluştu");
    }
  };
  return (
    <div>
      <h4>Ana kategoriler</h4>
      <Input
        type="select"
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(parseInt(e.target.value))}
      >
        <option>Ana ürün tipi kategorisi seçiniz</option>
        {mainCategories.map((item, key) => (
          <option key={key} value={item.Id}>
            {item.Name}
          </option>
        ))}
      </Input>
      {selectedCategory ? (
        <div>
          <h4>Alt kategoriler</h4>
          <ul>
            {subCategories.map((item, key) => (
              <li key={key}>
                <Input
                  type="checkbox"
                  checked={selectedCategoryValues[item.Id] ? true : false}
                  id={"sub-" + key}
                  onChange={(e) =>
                    setSelectedCategoryValues({
                      ...selectedCategoryValues,
                      [item.Id]: !!!selectedCategoryValues[item.Id],
                    })
                  }
                />
                <label htmlFor={"sub-" + key}> {item.Name}</label>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ""
      )}
      <Button onClick={save}>Kaydet</Button>
    </div>
  );
};
export default CompanyProductkitchenCategoryMatcher;
