import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import logo from "./images/logo.png";
import api from "./api";
import { eventEmitter } from "./utils";

function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPasword] = useState("");
  const [userType, setUserType] = useState(1);
  const isEntered = (e) => {
    if (e.key === "Enter") login();
  };
  const login = async () => {
    let login;
    if (userType === 2) login = await api.admin.login(username, password);
    else if (userType === 1)
      login = await api.company.login(username, password);
    else if (userType === 3)
      login = await api.companyBranch.login(username, password);
    if (login) {
      if (!login.error) {
        localStorage.setItem("token", login.token);
        eventEmitter.emit("logined", true);
      } else {
        alert("Giriş Başarısız");
      }
    } else {
      alert("Giriş Başarısız");
    }
  };
  return (
    <Col style={{ height: "100%" }}>
      <Row style={{ height: "100%" }}>
        <Col md={5}>
          <Row></Row>
        </Col>
        <Col
          md={2}
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            display: "flex",
          }}
        >
          <Form style={{ padding: "5px", border: "1px solid" }}>
            <FormGroup style={{ padding: "5px" }}>
              <img src={logo} style={{ width: "80%", height: "auto" }} />
            </FormGroup>
            <FormGroup style={{ marginTop: "10px" }}>
              <Label for="exampleEmail">Kullanıcı Adı</Label>
              <Input
                type="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                id="exampleEmail"
                placeholder="Kullanıcı Adı"
                onKeyDown={(e) => isEntered(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Şifre</Label>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPasword(e.target.value)}
                id="examplePassword"
                placeholder="Şifre"
                onKeyDown={(e) => isEntered(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="userType">Giriş Tipi</Label>
              <Input
                type="select"
                id="userType"
                value={userType}
                onChange={(e) => setUserType(parseInt(e.target.value))}
              >
                <option value={1}>Firma Girişi</option>
                <option value={3}>Bayi Girişi</option>
                <option value={2}>Admin Girişi</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Button style={{ marginTop: "5px" }} onClick={login}>
                Giriş
              </Button>
            </FormGroup>
            <FormGroup style={{ marginTop: "5px" }}>
              <Button
                color="danger"
                onClick={() => props.setIsApplication(true)}
              >
                Firma Başvurusu
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Col>
  );
}
export default Login;
