import axios from "axios";
import { getToken } from "./utils";

// const apiUrl = "http://localhost:80";
// export const apiUrl = "/";
// export const apiUrl = "http://192.168.50.215";
export const apiUrl = "https://panel.bugunbendensin.com";
const api = {
  admin: {
    login: async (Username, Password) => {
      const login = await axios.post(`${apiUrl}/login/adminLogin`, {
        Username,
        Password,
      });
      return login.data;
    },
    loginWithToken: async (token) => {
      const loginWithToken = await axios.get(
        `${apiUrl}/login/adminLoginWithToken/${token}`
      );
      return loginWithToken.data;
    },
    companyCategory: {
      get: async () => {
        const get = await axios.get(`${apiUrl}/admin/companyCategory`, {
          headers: { Authorization: getToken() },
        });
        return get.data;
      },
      create: async (row) => {
        const create = await axios.post(
          `${apiUrl}/admin/companyCategory`,
          row,
          {
            headers: { Authorization: getToken() },
          }
        );
        return create.data;
      },
      update: async (id, row) => {
        const update = await axios.put(
          `${apiUrl}/admin/companyCategory/${id}`,
          row,
          {
            headers: { Authorization: getToken() },
          }
        );
        return update.data;
      },
      delete: async (id) => {
        const del = await axios.delete(
          `${apiUrl}/admin/companyCategory/${id}`,
          {
            headers: { Authorization: getToken() },
          }
        );
        return del.data;
      },
    },
    companyProductKitchenCategory: {
      get: async (sub) => {
        const get = await axios.get(
          `${apiUrl}/admin/kitchenCategory${sub ? "?IsSub=true" : ""}`,
          {
            headers: { Authorization: getToken() },
          }
        );
        return get.data;
      },
      create: async (row) => {
        const create = await axios.post(
          `${apiUrl}/admin/kitchenCategory`,
          row,
          {
            headers: { Authorization: getToken() },
          }
        );
        return create.data;
      },
      update: async (id, row) => {
        const update = await axios.put(
          `${apiUrl}/admin/kitchenCategory/${id}`,
          row,
          {
            headers: { Authorization: getToken() },
          }
        );
        return update.data;
      },
      delete: async (id) => {
        const del = await axios.delete(
          `${apiUrl}/admin/kitchenCategory/${id}`,
          {
            headers: { Authorization: getToken() },
          }
        );
        return del.data;
      },
      getMapper: async (id) => {
        const get = await axios.get(
          `${apiUrl}/admin/kitchenCategoryMapper/${id}`,
          {
            headers: { Authorization: getToken() },
          }
        );
        return get.data;
      },
      updateMapper: async (id, values) => {
        const get = await axios.put(
          `${apiUrl}/admin/kitchenCategoryMapper/${id}`,
          {
            ids: values,
          },
          {
            headers: { Authorization: getToken() },
          }
        );
        return get.data;
      },
    },
    company: {
      get: async () => {
        const get = await axios.get(`${apiUrl}/admin/company`, {
          headers: { Authorization: getToken() },
        });
        return get.data;
      },
      create: async (row) => {
        const create = await axios.post(`${apiUrl}/admin/company`, row, {
          headers: { Authorization: getToken() },
        });
        return create.data;
      },
      update: async (id, row) => {
        const update = await axios.put(`${apiUrl}/admin/company/${id}`, row, {
          headers: { Authorization: getToken() },
        });
        return update.data;
      },
      delete: async (id) => {
        const del = await axios.delete(`${apiUrl}/admin/company/${id}`, {
          headers: { Authorization: getToken() },
        });
        return del.data;
      },
      requests: async () => {
        const get = await axios.get(`${apiUrl}/admin/companyRequest`, {
          headers: { Authorization: getToken() },
        });
        return get.data;
      },
      requestUpdate: async (id, row) => {
        const update = await axios.put(
          `${apiUrl}/admin/companyRequest/${id}`,
          row,
          {
            headers: { Authorization: getToken() },
          }
        );
        return update.data;
      },
    },
    companyProduct: {
      requests: async () => {
        const get = await axios.get(`${apiUrl}/admin/waitingProducts`, {
          headers: { Authorization: getToken() },
        });
        return get.data;
      },
      requestUpdate: async (id, row) => {
        const update = await axios.put(
          `${apiUrl}/admin/waitingProducts/${id}`,
          row,
          {
            headers: { Authorization: getToken() },
          }
        );
        return update.data;
      },
    },
    companyBranch: {
      get: async () => {
        const get = await axios.get(`${apiUrl}/admin/companyBranch`, {
          headers: { Authorization: getToken() },
        });
        return get.data;
      },
      create: async (row) => {
        const create = await axios.post(`${apiUrl}/admin/companyBranch`, row, {
          headers: { Authorization: getToken() },
        });
        return create.data;
      },
      update: async (id, row) => {
        const update = await axios.put(
          `${apiUrl}/admin/companyBranch/${id}`,
          row,
          {
            headers: { Authorization: getToken() },
          }
        );
        return update.data;
      },
      delete: async (id) => {
        const del = await axios.delete(`${apiUrl}/admin/companyBranch/${id}`, {
          headers: { Authorization: getToken() },
        });
        return del.data;
      },
    },
    campaign: {
      get: async () => {
        const get = await axios.get(`${apiUrl}/admin/campaign`, {
          headers: { Authorization: getToken() },
        });
        return get.data;
      },
      create: async (row) => {
        const create = await axios.post(`${apiUrl}/admin/campaign`, row, {
          headers: { Authorization: getToken() },
        });
        return create.data;
      },
      update: async (id, row) => {
        const update = await axios.put(`${apiUrl}/admin/campaign/${id}`, row, {
          headers: { Authorization: getToken() },
        });
        return update.data;
      },
      delete: async (id) => {
        const del = await axios.delete(`${apiUrl}/admin/campaign/${id}`, {
          headers: { Authorization: getToken() },
        });
        return del.data;
      },
    },
    openingBanner: {
      get: async () => {
        const get = await axios.get(`${apiUrl}/admin/openingBanner`, {
          headers: { Authorization: getToken() },
        });
        return get.data;
      },
      create: async (row) => {
        const create = await axios.post(`${apiUrl}/admin/openingBanner`, row, {
          headers: { Authorization: getToken() },
        });
        return create.data;
      },
      update: async (id, row) => {
        const update = await axios.put(
          `${apiUrl}/admin/openingBanner/${id}`,
          row,
          {
            headers: { Authorization: getToken() },
          }
        );
        return update.data;
      },
      delete: async (id) => {
        const del = await axios.delete(`${apiUrl}/admin/openingBanner/${id}`, {
          headers: { Authorization: getToken() },
        });
        return del.data;
      },
    },
    tutorialVideos: {
      get: async () => {
        const get = await axios.get(`${apiUrl}/admin/tutorialVideos`, {
          headers: { Authorization: getToken() },
        });
        return get.data;
      },
      create: async (row) => {
        const create = await axios.post(`${apiUrl}/admin/tutorialVideos`, row, {
          headers: { Authorization: getToken() },
        });
        return create.data;
      },
      update: async (id, row) => {
        const update = await axios.put(
          `${apiUrl}/admin/tutorialVideos/${id}`,
          row,
          {
            headers: { Authorization: getToken() },
          }
        );
        return update.data;
      },
      delete: async (id) => {
        const del = await axios.delete(`${apiUrl}/admin/tutorialVideos/${id}`, {
          headers: { Authorization: getToken() },
        });
        return del.data;
      },
    },
    feeCalculator: async (
      startDate,
      endDate,
      isPayyed,
      status,
      companyId,
      companyBranchId
    ) => {
      const get = await axios.get(`${apiUrl}/admin/feeCalculator`, {
        headers: { Authorization: getToken() },
        params: {
          startDate,
          endDate,
          isPayyed,
          status,
          companyId,
          companyBranchId,
        },
      });
      return get.data;
    },
    companiesWithBranches: async () => {
      const get = await axios.get(`${apiUrl}/admin/companiesWithBranches`, {
        headers: { Authorization: getToken() },
      });
      return get.data;
    },
    companyStatistics: async (id) => {
      const get = await axios.get(`${apiUrl}/admin/companyStatistics/${id}`, {
        headers: { Authorization: getToken() },
      });
      return get.data;
    },
    companyBranches: async (companyId) => {
      const get = await axios.get(
        `${apiUrl}/admin/companyBranches/${companyId}`,
        {
          headers: { Authorization: getToken() },
        }
      );
      return get.data;
    },
    getOrderByManualCode: async (manualCode) => {
      const get = await axios.get(
        `${apiUrl}/admin/getOrderByManualCode/${manualCode}`,
        {
          headers: { Authorization: getToken() },
        }
      );
      return get.data;
    },
    getCancelledOrders: async () => {
      const get = await axios.get(`${apiUrl}/admin/getCancelledOrders`, {
        headers: { Authorization: getToken() },
      });
      return get.data;
    },
    cancelOrder: async (orderId, CancelDescription) => {
      const get = await axios.post(
        `${apiUrl}/admin/cancelOrder/${orderId}`,
        {
          CancelDescription,
        },
        {
          headers: { Authorization: getToken() },
        }
      );
      return get.data;
    },
    companyDefaultProductCategory: {
      get: async () => {
        const get = await axios.get(
          `${apiUrl}/admin/defaultCompanyProductCategory`,
          {
            headers: { Authorization: getToken() },
          }
        );
        return get.data;
      },
      create: async (row) => {
        const create = await axios.post(
          `${apiUrl}/admin/defaultCompanyProductCategory`,
          row,
          {
            headers: { Authorization: getToken() },
          }
        );
        return create.data;
      },
      update: async (id, row) => {
        const update = await axios.put(
          `${apiUrl}/admin/defaultCompanyProductCategory/${id}`,
          row,
          {
            headers: { Authorization: getToken() },
          }
        );
        return update.data;
      },
      delete: async (id) => {
        const del = await axios.delete(
          `${apiUrl}/admin/defaultCompanyProductCategory/${id}`,
          {
            headers: { Authorization: getToken() },
          }
        );
        return del.data;
      },
    },
    interaction: {
      get: async (type) => {
        const get = await axios.get(`${apiUrl}/admin/interactions/${type}`, {
          headers: { Authorization: getToken() },
        });
        return get.data;
      },
      send: async (
        data = {
          Type: "",
          Value: "",
        }
      ) => {
        const create = await axios.post(`${apiUrl}/admin/interactions`, data, {
          headers: { Authorization: getToken() },
        });
        return create.data;
      },
    },
    invoices: {
      get: async (page) => {
        const get = await axios.get(`${apiUrl}/admin/invoices`, {
          headers: { Authorization: getToken() },
          params: { page },
        });
        return get.data;
      },
      create: async (manualCode) => {
        const get = await axios.get(`${apiUrl}/admin/invoices/${manualCode}`, {
          headers: { Authorization: getToken() },
        });
        return get.data;
      },
    },
    allOrders: async () => {
      const get = await axios.get(`${apiUrl}/admin/getAllOrders`, {
        headers: { Authorization: getToken() },
      });
      return get.data;
    },
  },
  cities: async () => {
    const get = await axios.get(`${apiUrl}/admin/cities`, {
      headers: { Authorization: getToken() },
    });
    return get.data;
  },
  districts: async (cityId) => {
    const get = await axios.get(`${apiUrl}/admin/districts/${cityId}`, {
      headers: { Authorization: getToken() },
    });
    return get.data;
  },

  company: {
    login: async (Username, Password) => {
      const login = await axios.post(`${apiUrl}/login/companyLogin`, {
        Username,
        Password,
      });
      return login.data;
    },
    loginWithToken: async (token) => {
      const loginWithToken = await axios.get(
        `${apiUrl}/login/companyLoginWithToken/${token}`
      );
      return loginWithToken.data;
    },
    companyProductCategory: {
      get: async () => {
        const get = await axios.get(`${apiUrl}/company/productCategory`, {
          headers: { Authorization: getToken() },
        });
        return get.data;
      },
      create: async (row) => {
        const create = await axios.post(
          `${apiUrl}/company/productCategory`,
          row,
          {
            headers: { Authorization: getToken() },
          }
        );
        return create.data;
      },
      update: async (id, row) => {
        const update = await axios.put(
          `${apiUrl}/company/productCategory/${id}`,
          row,
          {
            headers: { Authorization: getToken() },
          }
        );
        return update.data;
      },
      delete: async (id) => {
        const del = await axios.delete(
          `${apiUrl}/company/productCategory/${id}`,
          {
            headers: { Authorization: getToken() },
          }
        );
        return del.data;
      },
    },
    companyProduct: {
      get: async () => {
        const get = await axios.get(`${apiUrl}/company/product`, {
          headers: { Authorization: getToken() },
        });
        return get.data;
      },
      create: async (row) => {
        const create = await axios.post(`${apiUrl}/company/product`, row, {
          headers: { Authorization: getToken() },
        });
        return create.data;
      },
      update: async (id, row) => {
        const update = await axios.put(`${apiUrl}/company/product/${id}`, row, {
          headers: { Authorization: getToken() },
        });
        return update.data;
      },
      delete: async (id) => {
        const del = await axios.delete(`${apiUrl}/company/product/${id}`, {
          headers: { Authorization: getToken() },
        });
        return del.data;
      },
    },
    branches: async () => {
      const get = await axios.get(`${apiUrl}/company/branches`, {
        headers: { Authorization: getToken() },
      });
      return get.data;
    },
    userList: async () => {
      const get = await axios.get(`${apiUrl}/company/userList`, {
        headers: { Authorization: getToken() },
      });
      return get.data;
    },
    promotionCode: async (
      data = { CustomerId: "", Code: "", Discount: "" }
    ) => {
      const add = await axios.put(`${apiUrl}/company/promotionCode`, data, {
        headers: { Authorization: getToken() },
      });
      return add.data;
    },
    userIdsFiltered: async (params) => {
      const get = await axios.get(`${apiUrl}/company/userIdsFiltered`, {
        headers: { Authorization: getToken() },
        params,
      });
      return get.data;
    },
    feeCalculator: async (
      startDate,
      endDate,
      isPayyed,
      status,
      companyBranchId
    ) => {
      const get = await axios.get(`${apiUrl}/company/feeCalculator`, {
        headers: { Authorization: getToken() },
        params: {
          startDate,
          endDate,
          isPayyed,
          status,
          companyBranchId,
        },
      });
      return get.data;
    },
    addProductFromXml: async (xmlString = "") => {
      const create = await axios.post(
        `${apiUrl}/company/addProductFromXml`,
        { xmlstr: xmlString },
        {
          headers: { Authorization: getToken() },
        }
      );
      return create.data;
    },
    companyProductKitchenCategory: async () => {
      const get = await axios.get(`${apiUrl}/company/kitchenCategory`, {
        headers: { Authorization: getToken() },
      });
      return get.data;
    },
  },
  companyBranch: {
    login: async (Username, Password) => {
      const login = await axios.post(`${apiUrl}/login/companyBranchLogin`, {
        Username,
        Password,
      });
      return login.data;
    },
    loginWithToken: async (token) => {
      const loginWithToken = await axios.get(
        `${apiUrl}/login/companyBranchLoginWithToken/${token}`
      );
      return loginWithToken.data;
    },
    orders: async (params) => {
      const data = await axios.get(`${apiUrl}/companyBranch/orders`, {
        params,
        headers: {
          Authorization: getToken(),
        },
      });
      return data.data;
    },
    feeCalculator: async (startDate, endDate, isPayyed, status) => {
      const get = await axios.get(`${apiUrl}/companyBranch/feeCalculator`, {
        headers: { Authorization: getToken() },
        params: {
          startDate,
          endDate,
          isPayyed,
          status,
        },
      });
      return get.data;
    },
    getOrder: async (jwtSignedToken) => {
      const getOrder = await axios.post(
        `${apiUrl}/companyBranch/order`,
        {
          jwtSignedToken,
        },
        {
          headers: { Authorization: getToken() },
        }
      );
      return getOrder.data;
    },
    getOrderByManualCode: async (manualCode) => {
      const getOrder = await axios.post(
        `${apiUrl}/companyBranch/order`,
        {
          manualCode,
        },
        {
          headers: { Authorization: getToken() },
        }
      );
      return getOrder.data;
    },
    giveOrder: async (jwtSignedToken) => {
      const giveOrder = await axios.post(
        `${apiUrl}/companyBranch/giveOrder`,
        {
          jwtSignedToken,
        },
        {
          headers: { Authorization: getToken() },
        }
      );
      return giveOrder.data;
    },
    giveOrderByManualCode: async (manualCode) => {
      const giveOrder = await axios.post(
        `${apiUrl}/companyBranch/giveOrder`,
        {
          manualCode,
        },
        {
          headers: { Authorization: getToken() },
        }
      );
      return giveOrder.data;
    },
  },
  public: {
    cities: async () => {
      const get = await axios.get(`${apiUrl}/public/cities`, {
        headers: { Authorization: getToken() },
      });
      return get.data;
    },
    districts: async (cityId) => {
      const get = await axios.get(`${apiUrl}/public/districts/${cityId}`, {
        headers: { Authorization: getToken() },
      });
      return get.data;
    },
    companyCategory: {
      get: async () => {
        const get = await axios.get(`${apiUrl}/public/companyCategory`, {
          headers: { Authorization: getToken() },
        });
        return get.data;
      },
    },
    company: {
      create: async (row) => {
        const create = await axios.post(
          `${apiUrl}/public/companyRequest`,
          row,
          {
            headers: { Authorization: getToken() },
          }
        );
        return create.data;
      },
    },
    tutorialVideos: {
      get: async () => {
        const get = await axios.get(`${apiUrl}/public/tutorialVideos`, {
          headers: { Authorization: getToken() },
        });
        return get.data;
      },
    },
  },
};
export default api;
