import { useEffect, useState } from "react";
import api, { apiUrl } from "../api";
import AddAndEditTable from "../Components/AddAndEditTable";
const CompanyCategory = () => {
  const [companies, setCompanies] = useState([]);
  const [companyCategories, setCompanyCategories] = useState([]);
  const [companyKitchenCategories, setcompanyKitchenCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  useEffect(() => {
    getCompanyCategoryList();
    getCompanyKitchenCategoryList();
    getCompanyList();
    getCityList();
  }, []);
  const getCompanyCategoryList = async () => {
    const companyCategories = await api.admin.companyCategory.get();
    !companyCategories.error && setCompanyCategories(companyCategories);
  };
  const getCompanyKitchenCategoryList = async () => {
    const companyCategories =
      await api.admin.companyProductKitchenCategory.get();
    !companyCategories.error && setcompanyKitchenCategories(companyCategories);
  };
  const getCityList = async () => {
    const cities = await api.cities();

    !cities.error && setCities(cities);
  };
  const getDistrictList = async (cityId) => {
    const districts = await api.districts(cityId);
    !districts.error && setDistricts(districts);
  };
  const getCompanyList = async () => {
    const companies = await api.admin.company.get();
    !companies.error &&
      setCompanies(
        companies.map((item) => ({
          ...item,
          LogoImageUrl: apiUrl + "/static/companyImages/" + item.Id + ".jpg",
        }))
      );
  };
  const createCompany = async (row) => {
    const create = await api.admin.company.create(row);
    if (!create.error) {
      getCompanyList();
      return alert("Firma Oluşturuldu.");
    }
    if (create.error === 22) {
      return alert("Böyle bir kullanıcı bulunuyor");
    }
    alert(
      "Firma oluşturulamadı.(Hatalar :" +
        create.errors?.map((item) => item.msg).join(",") +
        ")"
    );
  };
  const updateCompany = async (id, row) => {
    const update = await api.admin.company.update(id, row);
    if (!update.error) {
      getCompanyList();
      return alert("Firma Güncellendi.");
    }
    if (update.message) return alert(update.message);
    alert(
      "Firma Güncellenemedi.(Hatalar :" +
        update.errors?.map((item) => item.msg).join(",") +
        ")"
    );
  };
  const deleteCompany = async (id) => {
    const confirmm = window.confirm("Silmek istediğinize emin misiniz?");
    if (confirmm) {
      const del = await api.admin.company.delete(id);
      if (!del.error) {
        getCompanyList();
        return alert("Firma Silindi.");
      }
      alert("Firma Silinemedi.");
    }
  };
  return (
    <AddAndEditTable
      type="Firma"
      rows={companies}
      cols={[
        {
          name: "Name",
          type: "text",
          label: "Firma Adı",
        },
        {
          name: "SmallName",
          type: "text",
          label: "Kısa Adı",
        },
        {
          name: "Description",
          type: "text",
          label: "Şirket Açıklaması",
        },
        {
          name: "Commission",
          type: "number",
          label: "Komisyon Oranı ( Örnek : %5 )",
        },
        {
          type: "select",
          name: "CompanyCategoryId",
          label: "Firma Kategorisi",
          value: "CompanyCategoryId",
          values: companyCategories.map((item) => ({
            ...item,
            label: item.Name,
            id: item.Id,
          })),
          noShow: true,
        },
        {
          type: "select",
          name: "CompanyKitchenCategoryId",
          label: "Firma Ana Ürün Tipi Kategorisi",
          value: "CompanyKitchenCategoryId",
          values: companyKitchenCategories.map((item) => ({
            ...item,
            label: item.Name,
            id: item.Id,
          })),
          noShow: true,
        },
        {
          type: "select",
          label: "Şehir",
          value: "CityId",
          name: "CityId",
          values: cities.map((item) => ({
            ...item,
            label: item.Name,
            id: item.Id,
          })),
          onChange: (cityId) => {
            getDistrictList(cityId);
          },
          noShow: true,
        },
        {
          type: "select",
          label: "İlçe",
          value: "DistrictId",
          name: "DistrictId",
          values: districts.map((item) => ({
            ...item,
            label: item.Name,
            id: item.Id,
          })),
          noShow: true,
        },
        {
          name: "VergiDaire",
          type: "text",
          label: "Vergi Daire",
        },
        {
          name: "VergiNo",
          type: "text",
          label: "Vergi No",
        },
        {
          name: "Address",
          type: "text",
          label: "Adres",
        },
        {
          name: "GoogleMapAddressUrl",
          type: "text",
          label: "Google Map adresi",
          noShow: true,
        },
        {
          name: "AuthorName",
          type: "text",
          label: "Yetkili Adı Soyadı",
        },
        {
          name: "AuthorRole",
          type: "text",
          label: "Yetkili Rolü",
          noShow: true,
        },
        {
          name: "AuthorMobileNumber",
          type: "text",
          label: "Yetkili Telefon Numarası",
        },
        {
          name: "AuthorMail",
          type: "text",
          label: "Yetkili Mail",
        },
        {
          name: "Username",
          type: "text",
          label: "Kullanıcı Adı",
          noShow: true,
        },
        {
          name: "Password",
          type: "password",
          label: "Şifre",
          noShow: true,
        },
        {
          name: "LogoImageUrl",
          type: "file",
          label: "Firma Logo",
          fileType: "image",
          width: "40px",
          height: "40px",
        },
        // {
        //   type: "text",
        //   name: "description",
        //   label: "Açıklama",
        // },
        // {
        //   type: "select",
        //   label: "Alt Kategori",
        //   value: "sub",
        //   values: categories.map((item) => ({
        //     ...item,
        //     label: item.nameWithSpaces,
        //     id: item._id,
        //   })),
        // },
      ]}
      create={(row) => createCompany(row)}
      update={(id, row) => updateCompany(id, row)}
      deletee={(id) => deleteCompany(id)}
    />
  );
};
export default CompanyCategory;
