import { useEffect, useState } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import api, { apiUrl } from "../api";
import AddAndEditTable from "../Components/AddAndEditTable";
import { userInfo } from "../utils";
import moment from "moment";
moment.locale("tr");
const CompanyProduct = () => {
  const [companyProductCategories, setCompanyProductCategories] = useState([]);
  const [companyProductKitchenCategories, setCompanyProductKitchenCategories] =
    useState([]);
  const [companyProducts, setCompanyProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      await getCompanyProductCategoriesList();
      await getCompanyProductKitchenCategoriesList();
      await getCompanyProductList();
    };
    fetchData();
  }, []);
  const user = userInfo();
  const getCompanyProductCategoriesList = async () => {
    const companyProductCategories =
      await api.company.companyProductCategory.get();
    !companyProductCategories.error &&
      setCompanyProductCategories(companyProductCategories);
  };
  const getCompanyProductKitchenCategoriesList = async () => {
    const companyProductKitchenCategories =
      await api.company.companyProductKitchenCategory();
    !companyProductKitchenCategories.error &&
      setCompanyProductKitchenCategories(companyProductKitchenCategories);
  };
  const getCompanyProductList = async () => {
    const companyProducts = await api.company.companyProduct.get();
    !companyProducts.error &&
      setCompanyProducts(
        companyProducts.map((item) => ({
          ...item,
          ProductOptions: (item.ProductOptions ? item.ProductOptions : []).map(
            (item2) => ({
              ...item2,
              ImageUrl:
                apiUrl +
                "/static/companyProductOptionImages/" +
                item2.Id +
                ".jpg",
            })
          ),
          ImageUrls: (item.ImageUrls ? item.ImageUrls : []).map((item2) => ({
            ...item2,
            ImageUrl:
              apiUrl + "/static/companyProductOtherImages/" + item2.Id + ".jpg",
          })),
        }))
      );
  };
  const createCompanyProduct = async (row) => {
    const create = await api.company.companyProduct.create(row);
    if (!create.error) {
      getCompanyProductList();
      return alert(
        "Ürün Oluşturuldu. Admin onayından geçtikten sonra profilinizde listelenecektir."
      );
    }
    if (create.errors)
      return alert(
        "Ürün oluşturulamadı.(Hatalar :" +
          create.errors?.map((item) => item.msg).join(",") +
          ")"
      );
    if (create.message) return alert(create.message);
    alert("Ürün oluşturulamadı.");
  };
  const updateCompanyProduct = async (id, row) => {
    delete row.subs;
    const update = await api.company.companyProduct.update(id, row);
    if (!update.error) {
      getCompanyProductList();
      return alert("Ürün Güncellendi.");
    }
    if (update.errors)
      return alert(
        "Ürün Güncellenemedi.(Hatalar :" +
          update.errors?.map((item) => item.msg).join(",") +
          ")"
      );
    if (update.message) return alert(update.message);
    alert("Ürün Güncellenemedi.");
  };
  const deleteCompanyProduct = async (id) => {
    const confirmm = window.confirm("Silmek istediğinize emin misiniz?");
    if (confirmm) {
      const del = await api.company.companyProduct.delete(id);
      if (!del.error) {
        getCompanyProductList();
        return alert("Ürün Silindi.");
      }
      alert("Ürün Silinemedi.");
    }
  };
  return (
    <AddAndEditTable
      type="Firma Ürünleri"
      rows={companyProducts.map((item) => ({
        ...item,
        background: !item.CompanyProductKitchenCategoryTypeId
          ? "yellow"
          : undefined,
      }))}
      cols={[
        {
          type: "select",
          name: "CompanyProductKitchenCategoryTypeId",
          label: "Ürün Tipi Kategorisi",
          value: "CompanyProductKitchenCategoryTypeId",
          values: companyProductKitchenCategories.map((item) => ({
            ...item,
            label: item.Name,
            id: item.Id,
          })),
          noShow: true,
        },
        {
          type: "select",
          name: "CompanyProductCategoryId",
          label: "Ürün Menü Kategorisi",
          value: "CompanyProductCategoryId",
          values: companyProductCategories.map((item) => ({
            ...item,
            label: item.Name,
            id: item.Id,
          })),
          noShow: true,
        },
        {
          name: "Name",
          type: "text",
          label: "Ürün Adı",
        },
        {
          name: "SmallName",
          type: "text",
          label: "Ürün Kısa Adı",
        },
        {
          name: "Description",
          type: "text",
          label: "Ürün Açıklaması",
        },
        {
          name: "ProductCode",
          type: "text",
          label: "Ürün Kodu",
        },
        {
          name: "BarcodeCode",
          type: "text",
          label: "Ürün Barkod",
        },
        // {
        //   name: "Price",
        //   type: "floatNumber",
        //   label: "Ürün Fiyatı",
        //   step: 0.01,
        // },
        ...(user.isActivity
          ? [
              {
                name: "ActivityStartDate",
                type: "date",
                label: "Etkinlik başlangıç tarihi",
              },
              {
                name: "ActivityEndDate",
                type: "date",
                label: "Etkinlik bitiş tarihi",
              },
              {
                name: "ActivityStock",
                type: "number",
                label: "Etkinlik stok",
              },
            ]
          : []),
        {
          name: "ImageUrls",
          label: "Ürün Resimleri",
          type: "specialRender",
          specialRender: (updateNewRow, initialData) => {
            return (
              <ProductImages
                updateNewRow={updateNewRow}
                initialData={initialData}
              />
            );
          },
          noShow: true,
        },
        {
          name: "YoutubeUrl",
          type: "text",
          label: "Ürün Youtube Linki",
        },
        {
          name: "ProductOptions",
          type: "specialRender",
          specialRender: (updateNewRow, initialData) => {
            return (
              <ProductOptions
                updateNewRow={updateNewRow}
                initialData={initialData}
              />
            );
          },
          noShow: true,
        },
        {
          name: "IsApproved",
          type: "text",
          noShowEdit: true,
          noShowForm: true,
          label: "Onaylanma Durumu",
          callback: (data) => {
            if (data === 0)
              return <span color="yellow">Henüz Onaylanmadı</span>;
            else if (data === 1) return <span color="green">Onaylandı</span>;
            else if (data === 2) return <span color="red">Reddedildi</span>;
          },
        },
      ]}
      create={(row) => createCompanyProduct(row)}
      update={(id, row) => updateCompanyProduct(id, row)}
      deletee={(id) => deleteCompanyProduct(id)}
    />
  );
};
const ProductOptions = (props) => {
  const [productOptions, setProductOptions] = useState(props.initialData || []);
  useEffect(() => {
    props.initialData && setProductOptions(props.initialData);
  }, [props.initialData]);
  const updateProductOption = (index, key, value) => {
    const newProductOptions = productOptions.map((item, key2) => {
      if (index === key2) {
        item[key] = value;
      }
      return item;
    });
    setProductOptions(newProductOptions);
  };
  const addNewProductOption = () => {
    const newProductOptions = [...productOptions, { Name: "", Price: 0 }];
    setProductOptions(newProductOptions);
  };
  const deleteProductOption = (index) => {
    const newProductOptions = productOptions.filter(
      (item, key) => key !== index
    );
    setProductOptions(newProductOptions);
  };
  return (
    <Col md={6}>
      <Row>
        <Col md={4}>Seçenek Adı</Col>
        <Col md={2}>Seçenek Bendensin Fiyatı</Col>
        <Col md={2}>Seçenek Gerçek Fiyatı</Col>
        <Col md={2}>Seçenek Resmi</Col>
      </Row>
      <Row>
        <Col>
          {productOptions.map((item, key) => (
            <Row key={key} style={{ marginTop: key === 0 ? "0px" : "10px" }}>
              <Col md={4}>
                <Input
                  type="text"
                  value={item.Name}
                  onChange={(e) => {
                    updateProductOption(key, "Name", e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Input
                  type="number"
                  step={0.01}
                  value={item.Price}
                  onChange={(e) => {
                    updateProductOption(
                      key,
                      "Price",
                      parseFloat(e.target.value)
                    );
                  }}
                />
              </Col>
              <Col md={2}>
                <Input
                  type="number"
                  step={0.01}
                  value={item.RealPrice}
                  onChange={(e) => {
                    updateProductOption(
                      key,
                      "RealPrice",
                      parseFloat(e.target.value)
                    );
                  }}
                />
              </Col>
              <Col md={2}>
                {item.ImageUrl && (
                  <img
                    style={{ width: "40px", height: "40px" }}
                    src={item.ImageUrl}
                    alt="veri resmi"
                  />
                )}
                <Input
                  type="file"
                  key={Date.now()}
                  onChange={(e) => {
                    // console.log();
                    const file = e.target.files[0];
                    const reader = new FileReader();
                    reader.addEventListener(
                      "load",
                      () => {
                        updateProductOption(key, "ImageUrl", reader.result);
                      },
                      false
                    );
                    if (file) {
                      reader.readAsDataURL(file);
                    }
                  }}
                ></Input>
              </Col>
              <Col md={2}>
                <Button onClick={() => deleteProductOption(key)} color="danger">
                  Sil
                </Button>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col md={6}>
          <Button onClick={() => addNewProductOption()}>
            Yeni Özellik Ekle
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px", marginBottom: "50px" }}>
        <Col md={6}>
          <Button
            onClick={() =>
              props.updateNewRow &&
              props.updateNewRow("ProductOptions", productOptions)
            }
            color="primary"
          >
            Ürün Seçeneklerini Kaydet
          </Button>
        </Col>
      </Row>
    </Col>
  );
};
const ProductImages = (props) => {
  const [productImages, setProductImages] = useState(props.initialData || []);
  useEffect(() => {
    props.initialData && setProductImages(props.initialData);
  }, [props.initialData]);
  const updateProductImage = (index, key, value) => {
    const newProductImages = productImages.map((item, key2) => {
      if (index === key2) {
        item[key] = value;
      }
      return item;
    });
    setProductImages(newProductImages);
  };
  const addNewProductImage = () => {
    const newProductImages = [...productImages, {}];
    setProductImages(newProductImages);
  };
  const deleteProductImage = (index) => {
    const newProductImages = productImages.filter((item, key) => key !== index);
    setProductImages(newProductImages);
  };
  return (
    <Col md={6}>
      <Row>
        <Col md={4}>Ürün Resmi</Col>
      </Row>
      <Row>
        <Col>
          {productImages.map((item, key) => (
            <Row key={key} style={{ marginTop: key === 0 ? "0px" : "10px" }}>
              <Col md={2}>
                {item.ImageUrl && (
                  <img
                    style={{ width: "40px", height: "40px" }}
                    src={item.ImageUrl}
                    alt="veri resmi"
                  />
                )}
                <Input
                  type="file"
                  key={Date.now()}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const reader = new FileReader();
                    reader.addEventListener(
                      "load",
                      () => {
                        updateProductImage(key, "ImageUrl", reader.result);
                      },
                      false
                    );
                    if (file) {
                      reader.readAsDataURL(file);
                    }
                  }}
                ></Input>
              </Col>
              <Col md={2}>
                <Button onClick={() => deleteProductImage(key)} color="danger">
                  Sil
                </Button>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col md={6}>
          <Button onClick={() => addNewProductImage()}>Yeni Resim Ekle</Button>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px", marginBottom: "50px" }}>
        <Col md={6}>
          <Button
            onClick={() =>
              props.updateNewRow &&
              props.updateNewRow("ImageUrls", productImages)
            }
            color="primary"
          >
            Ürün Resimlerini Kaydet
          </Button>
        </Col>
      </Row>
    </Col>
  );
};
export default CompanyProduct;
