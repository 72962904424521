import { useCallback, useEffect, useState } from "react";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import api from "../api";

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [invoice, setInvoice] = useState();
  const [manualCode, setManualCode] = useState('');
  const getInvoicesList = useCallback(async (page) => {
    const invoices = await api.admin.invoices.get(page);
    setMaxPage(
      invoices.count % 10 === 0
        ? invoices.count / 10
        : parseInt(invoices.count / 10) + 1
    );
    setInvoices(invoices.rows);
  }, []);
  useEffect(() => getInvoicesList(page), [getInvoicesList, page]);
  const getInvoice = async (item) => {
    if (item.ParasutInvoiceDetails) {
      console.log(JSON.parse(item.ParasutInvoiceDetails));
      return setInvoice(JSON.parse(item.ParasutInvoiceDetails));
    }
    const createInvoice = await api.admin.invoices.create(item.ManualCode);
    if (createInvoice.ParasutInvoiceDetails) {
      console.log(JSON.parse(createInvoice.ParasutInvoiceDetails));
      setInvoice(JSON.parse(createInvoice.ParasutInvoiceDetails));
      getInvoicesList(page);
    } else if (createInvoice.error) {
      alert(createInvoice.message);
    } else {
      alert("Paraşüt hatası.");
    }
  };
  const handleManualCode = async () => {
    if(!manualCode) return alert("Manuel kod giriniz");
    const createInvoice = await api.admin.invoices.create(manualCode);
    if (createInvoice.ParasutInvoiceDetails) {
      setInvoice(JSON.parse(createInvoice.ParasutInvoiceDetails));
      getInvoicesList(page);
    } else if (createInvoice.error) {
      alert(createInvoice.message);
    } else {
      alert("Paraşüt hatası");
    }
  }
  return (
    <Col>
      <Modal isOpen={invoice ? true : false}>
        <ModalHeader>Fatura Detayları</ModalHeader>
        <ModalBody>
          <a
            href={`https://uygulama.parasut.com/${invoice?.parasut_company_id}/satislar/${invoice?.data?.id}`}
            target="blank"
          >
            Faturayı görüntüle
          </a>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => setInvoice()}>
            Kapat
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
					<Col md={2}>Manuel Kod ile Ara :</Col>
					<Col md={2}>
						<Input
							type="text"
							value={manualCode}
							onChange={(e) => setManualCode(e.target.value)}
						/>
					</Col>
					<Col md={2}>
						<Button onClick={handleManualCode}>Siparişi getir</Button>
					</Col>
				</Row>
      <Row>
        <h2>Faturalar</h2>
      </Row>
      <Row>
        <Table>
          <thead>
            <tr>
              <td>Id</td>
              <td>Ad Soyad</td>
              <td>Fatura</td>
            </tr>
          </thead>
          <tbody>
            {invoices.map((item, key) => (
              <tr key={key}>
                <td>{item.Id}</td>
                <td>{item.Sender.NameSurname}</td>
                <td>
                  <Button color="success" onClick={() => getInvoice(item)}>
                    Görüntüle
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <Row>
        <Pagination aria-label="Page navigation example">
          <PaginationItem>
            <PaginationLink
              previous
              href="#"
              onClick={() => {
                page - 1 > 0 && setPage(page - 1);
              }}
            />
          </PaginationItem>
          {[...new Array(maxPage)].map((item, key) => (
            <PaginationItem key={key + 1} active={key + 1 === page}>
              <PaginationLink href="#" onClick={() => setPage(key + 1)}>
                {key + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
          <PaginationItem>
            <PaginationLink
              next
              href="#"
              onClick={() => {
                page + 1 <= maxPage && setPage(page + 1);
              }}
            />
          </PaginationItem>
        </Pagination>
      </Row>
    </Col>
  );
};
export default Invoices;
