import { useEffect, useState } from "react";
import api from "../api";
import AddAndEditTable from "../Components/AddAndEditTable";
const TutorialVideos = () => {
  const [tutorialVideos, setTutorialVideos] = useState([]);
  useEffect(() => {
    getTutorialVideosList();
  }, []);
  const getTutorialVideosList = async () => {
    const tutorialVideos = await api.admin.tutorialVideos.get();
    !tutorialVideos.error && setTutorialVideos(tutorialVideos);
  };
  const createTutorialVideo = async (row) => {
    const create = await api.admin.tutorialVideos.create(row);
    if (!create.error) {
      getTutorialVideosList();
      return alert("Eğitim Videosu oluşturuldu.");
    }
    alert(
      "Eğitim Videosu oluşturulamadı.(Hatalar :" +
        create.errors?.map((item) => item.msg).join(",") +
        ")"
    );
  };
  const updateTutorialVideo = async (id, row) => {
    const update = await api.admin.tutorialVideos.update(id, row);
    if (!update.error) {
      getTutorialVideosList();
      return alert("Eğitim Videosu Güncellendi.");
    }
    alert(
      "Eğitim Videosu Güncellenemedi.(Hatalar :" +
        update.errors?.map((item) => item.msg).join(",") +
        ")"
    );
  };
  const deleteTutorialVideo = async (id) => {
    const confirmm = window.confirm("Silmek istediğinize emin misiniz?");
    if (confirmm) {
      const del = await api.admin.tutorialVideos.delete(id);
      if (!del.error) {
        getTutorialVideosList();
        return alert("Eğitim Videosu Silindi.");
      }
      alert("Eğitim Videosu Silinemedi.");
    }
  };
  return (
    <AddAndEditTable
      type="Eğitim Videoları"
      rows={tutorialVideos}
      cols={[
        {
          name: "Title",
          type: "text",
          label: "Başlık",
        },
        {
          name: "Url",
          type: "text",
          label: "Link",
        },
        {
          name: "Description",
          type: "richText",
          label: "Açıklama",
          noShow: true,
        },
      ]}
      create={(row) => createTutorialVideo(row)}
      update={(id, row) => updateTutorialVideo(id, row)}
      deletee={(id) => deleteTutorialVideo(id)}
    />
  );
};
export default TutorialVideos;
