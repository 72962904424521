import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import api from "./api";
import logo from "./images/logo.png";
const Application = (props) => {
  const [applicationData, setApplicationData] = useState({});
  const [companyCategories, setCompanyCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    getCompanyCategoryList();
    getCityList();
  }, []);
  const getCompanyCategoryList = async () => {
    const companyCategories = await api.public.companyCategory.get();
    !companyCategories.error && setCompanyCategories(companyCategories);
  };
  const getCityList = async () => {
    const cities = await api.public.cities();

    !cities.error && setCities(cities);
  };
  const getDistrictList = async (cityId) => {
    const districts = await api.public.districts(cityId);
    !districts.error && setDistricts(districts);
  };
  const isEntered = (e) => {
    if (e.key === "Enter") application();
  };
  const application = async () => {
    const save = await api.public.company.create(applicationData);
    if (!save.error) {
      alert("Başvunuruz alındı. En kısa zamanda aranacaksınız");
      setErrors([]);
      return setApplicationData({});
    }
    setErrors(save.errors);
    setTimeout(() => (window.location = "#errors"), 100);
    alert("Başvuru hatası oluştu!");
  };
  const updateNewRow = (key, value) => {
    const newAdds = {};
    newAdds[key] = value;
    setApplicationData({ ...applicationData, ...newAdds });
  };
  const cols = [
    {
      name: "Name",
      type: "text",
      label: "Firma Adı",
    },
    {
      name: "SmallName",
      type: "text",
      label: "Kısa Adı",
    },
    {
      name: "Description",
      type: "text",
      label: "Şirket Açıklaması",
    },
    {
      name: "Commission",
      type: "number",
      label: "Komisyon Oranı ( Örnek : %5 )",
    },
    {
      type: "select",
      name: "CompanyCategoryId",
      label: "Firma Kategorisi",
      value: "CompanyCategoryId",
      values: companyCategories.map((item) => ({
        ...item,
        label: item.Name,
        id: item.Id,
      })),
      noShow: true,
    },
    {
      type: "select",
      label: "Şehir",
      value: "CityId",
      name: "CityId",
      values: cities.map((item) => ({
        ...item,
        label: item.Name,
        id: item.Id,
      })),
      onChange: (cityId) => {
        getDistrictList(cityId);
      },
      noShow: true,
    },
    {
      type: "select",
      label: "İlçe",
      value: "DistrictId",
      name: "DistrictId",
      values: districts.map((item) => ({
        ...item,
        label: item.Name,
        id: item.Id,
      })),
      noShow: true,
    },
    {
      name: "VergiDaire",
      type: "text",
      label: "Vergi Daire",
    },
    {
      name: "VergiNo",
      type: "text",
      label: "Vergi No",
    },
    {
      name: "Address",
      type: "text",
      label: "Adres",
    },
    {
      name: "AuthorName",
      type: "text",
      label: "Yetkili Adı Soyadı",
    },
    {
      name: "AuthorRole",
      type: "text",
      label: "Yetkili Rolü",
      noShow: true,
    },
    {
      name: "AuthorMobileNumber",
      type: "text",
      label: "Yetkili Telefon Numarası",
    },
    {
      name: "AuthorMail",
      type: "text",
      label: "Yetkili Mail",
    },
    {
      name: "Username",
      type: "text",
      label: "Kullanıcı Adı",
      noShow: true,
    },
    {
      name: "Password",
      type: "password",
      label: "Şifre",
      noShow: true,
    },
    {
      name: "LogoImageUrl",
      type: "file",
      label: "Firma Logo",
      fileType: "image",
      width: "40px",
      height: "40px",
    },
  ];
  return (
    <Col>
      <Row style={{ height: "100%" }}>
        <Col md={5}>
          <Row></Row>
        </Col>

        <Col
          md={2}
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            display: "flex",
          }}
        >
          <Form style={{ padding: "5px", border: "1px solid" }}>
            <FormGroup style={{ padding: "5px" }}>
              <img src={logo} style={{ width: "80%", height: "auto" }} />
            </FormGroup>
            {errors.length > 0 && (
              <FormGroup id="errors">
                {
                  <Card color="danger">
                    <CardBody>
                      Lütfen kırmızı ile belirtilen hataları düzeltin.
                    </CardBody>
                  </Card>
                }
              </FormGroup>
            )}
            {cols.map((item, key) => {
              const errorIncludes = errors
                .map((item) => item.param)
                .includes(item.name);
              const style = errorIncludes ? { border: "1px solid red" } : {};
              return (
                <FormGroup key={key}>
                  <Label for={item.name}>{item.label}</Label>
                  {(item.type === "text" ||
                    item.type === "password" ||
                    item.type === "number") && (
                    <Input
                      type={item.type}
                      value={applicationData[item.name] || ""}
                      id={item.label}
                      style={style}
                      onChange={(e) => {
                        updateNewRow(
                          item.name,
                          item.type === "number"
                            ? parseInt(e.target.value)
                            : e.target.value
                        );
                      }}
                      onKeyDown={isEntered}
                    />
                  )}
                  {item.type === "floatNumber" && (
                    <Input
                      type="number"
                      value={applicationData[item.name] || ""}
                      id={item.label}
                      step={item.step}
                      style={style}
                      onChange={(e) =>
                        updateNewRow(item.name, parseFloat(e.target.value))
                      }
                      onKeyDown={isEntered}
                    />
                  )}
                  {item.type === "select" && (
                    <Input
                      value={applicationData[item.value] || ""}
                      type="select"
                      id={item.label}
                      style={style}
                      onChange={(e) => {
                        updateNewRow(item.value, e.target.value);
                        item.onChange && item.onChange(e.target.value);
                      }}
                      onKeyDown={isEntered}
                    >
                      <option value="0">Seçiniz</option>
                      {item.values.map((item2, key2) => (
                        <option key={key2} value={item2.id}>
                          {item2.label}
                        </option>
                      ))}
                    </Input>
                  )}
                  {item.type === "file" && (
                    <React.Fragment>
                      {applicationData[item.name] && (
                        <React.Fragment>
                          <img
                            src={
                              applicationData[item.name] +
                              (applicationData[item.name] &&
                              !applicationData[item.name].startsWith("data")
                                ? "?v=" + Date.now()
                                : "")
                            }
                            style={{ width: "40px", height: "40px" }}
                          />
                          <br />
                        </React.Fragment>
                      )}
                      <Input
                        type="file"
                        id={item.label}
                        key={Date.now()}
                        style={style}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          const reader = new FileReader();
                          reader.addEventListener(
                            "load",
                            () => {
                              updateNewRow(item.name, reader.result);
                            },
                            false
                          );
                          if (file) {
                            reader.readAsDataURL(file);
                          }
                        }}
                      />
                    </React.Fragment>
                  )}
                </FormGroup>
              );
            })}
            <FormGroup>
              <Button
                style={{ marginTop: "5px" }}
                color="success"
                onClick={() => application()}
              >
                Başvuru
              </Button>
            </FormGroup>
            <FormGroup style={{ marginTop: "5px" }}>
              <Button
                color="danger"
                onClick={() => props.setIsApplication(false)}
              >
                Girişe Dön
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Col>
  );
};
export default Application;
