import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import Main from "./Main";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-pro-sidebar/dist/css/styles.css";
import React, { useEffect, useState } from "react";
import { eventEmitter, isLogin } from "./utils";
import Login from "./Login";
import Application from "./Application";

function App() {
  const [logined, setLogined] = useState(false);
  const [isApplication, setIsApplication] = useState(false);
  useEffect(async () => {
    const fetchLogin = async () => {
      const loginControl = await isLogin();
      setLogined(loginControl);
    };
    fetchLogin();
    eventEmitter.addListener("logined", (data) => {
      setLogined(data);
    });
  }, []);
  // return <Main />;
  return (
    <>
      {logined && <Main />}
      {!logined && !isApplication && (
        <Login setIsApplication={setIsApplication} />
      )}
      {isApplication && !logined && (
        <Application setIsApplication={setIsApplication} />
      )}
    </>
  );
}

export default App;
