import { useEffect, useState } from 'react';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Col,
	Input,
	Row,
} from 'reactstrap';
import api from '../api';

const PromotionCode = () => {
	const [userId, setUserId] = useState([]);
	const [code, setCode] = useState('');
	const [discount, setDiscount] = useState(1);

	const [users, setUsers] = useState([]);
	const [cities, setCities] = useState([]);
	const [selectedCity, setSelectedCity] = useState();
	const [districts, setDistricts] = useState([]);
	const [selectedDistrict, setSelectedDistrict] = useState();
	const [nameListString, setNameListString] = useState('');
	const [cantFindedUsers, setCantFindedUsers] = useState([]);
	useEffect(() => {
		const fetchData = async () => {
			const users = await api.company.userList();
			setUsers(users);
			getCities();
		};
		fetchData();
	}, []);
	useEffect(() => selectedCity && getDistricts(), [selectedCity]);
	const add = async () => {
		const data = {
			CustomerId: userId,
			Code: code,
			Discount: discount,
		};
		const send = await api.company.promotionCode(data);
		if (!send.error) {
			return alert('Kod gönderildi');
		}
		return alert("Kod gönderilemedi.(Hatalar :"+send.errors?.map(item => item.msg).join(",")+")");
	};
	const getCities = async () => {
		const cities = await api.public.cities();
		setCities(cities);
	};
	const getDistricts = async () => {
		const districts = await api.public.districts(selectedCity);
		setDistricts(districts);
	};
	const searchIds = async () => {
		if (!selectedCity && !selectedDistrict && !nameListString) {
			return setUserId(users.map((item) => item.Id));
		}
		const filter = {
			cityId: selectedCity,
			districtId: selectedDistrict,
			nameList: nameListString,
		};
		if (isNaN(filter.cityId)) delete filter.cityId;
		if (isNaN(filter.districtId)) delete filter.districtId;
		const userIdsFiltered = await api.company.userIdsFiltered(filter);
		setUserId(userIdsFiltered.findedUsers);
		setCantFindedUsers(userIdsFiltered.cantFindedUsers);
	};
	return (
		<Col>
			<Row>
				<h3>Promosyon kodu gönder</h3>
			</Row>
			<Row>
				Kullanıcı:
				<Input
					type="select"
					onChange={(e) => {
						// const id = parseInt(e.target.value);
						const selectedIds = [];
						for (let i = 0; i < e.target.options.length; i++) {
							const opt = e.target.options[i];
							if (opt.selected) selectedIds.push(parseInt(opt.value));
						}
						setUserId(selectedIds);
					}}
					value={userId}
					multiple
				>
					<option>Seçiniz</option>
					{users.map((item, key) => (
						<option key={key} value={item.Id}>
							{item.NameSurname}
						</option>
					))}
				</Input>
			</Row>
			<Row>
				<h3>Kullanıcıları il, ilçe ve isim listesine göre filtreleyerek seç</h3>
			</Row>
			<Row>
				İl:
				<Input
					type="select"
					value={selectedCity}
					onChange={(e) => setSelectedCity(parseInt(e.target.value))}
				>
					<option value="0">Seçiniz</option>
					{cities.map((item, key) => (
						<option value={item.Id} key={key}>
							{item.Name}
						</option>
					))}
				</Input>
			</Row>
			<Row>
				İl:
				<Input
					type="select"
					value={selectedDistrict}
					onChange={(e) => setSelectedDistrict(parseInt(e.target.value))}
				>
					<option value="0">Seçiniz</option>
					{districts.map((item, key) => (
						<option value={item.Id} key={key}>
							{item.Name}
						</option>
					))}
				</Input>
			</Row>
			<Row>
				İsim Listesi :
				<Input
					type="textarea"
					placeholder="Örnek : Ad2 Soyad2, Ad2 Soyad2, Ad3 Soyad3"
					onChange={(e) => setNameListString(e.target.value)}
					value={nameListString}
				></Input>
			</Row>
			<Row style={{ marginTop: '10px' }}>
				<Col>
					<Button
						onClick={() => {
							searchIds();
						}}
						color="success"
					>
						İsimleri seç
					</Button>
				</Col>
				<Col>
					<Button
						onClick={() => {
							setSelectedCity(0);
							setSelectedDistrict(0);
							setDistricts([]);
							setNameListString();
						}}
						color="danger"
					>
						Filtreleri temizle
					</Button>
				</Col>
				<Col>
					<Button onClick={() => setUserId([])}>Seçili kullanıcıları temizle</Button>
				</Col>
			</Row>
			{cantFindedUsers.length > 0 && (
				<Row style={{ marginTop: '10px' }}>
					<Card color="danger" style={{ color: 'white' }}>
						<CardHeader>
							<CardTitle tag="h4">Bazı kullanıcılar bulunamadı</CardTitle>
						</CardHeader>
						<CardBody>
							{cantFindedUsers.map((item, key) => (
								<Row key={key}>- {item}</Row>
							))}
						</CardBody>
					</Card>
				</Row>
			)}
			<Row>
				Kod:
				<Input type="text" value={code} onChange={(e) => setCode(e.target.value)} />
			</Row>
			<Row>
				İndirim ( TL ) :
				<Input
					type="number"
					value={discount}
					onChange={(e) => setDiscount(parseInt(e.target.value))}
				/>
			</Row>
			<Row>
				<Button onClick={add}>Kod gönder</Button>
			</Row>
		</Col>
	);
};
export default PromotionCode;
