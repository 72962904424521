import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Table,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";

function AddAndEditTable(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [newRowData, setNewRowData] = useState({});
  const type = props.type || "";
  const cols = props.cols || [];
  const rows = props.rows || [];
  const updateNewRow = (key, value) => {
    const newAdds = {};
    newAdds[key] = value;
    setNewRowData({ ...newRowData, ...newAdds });
  };
  const buttonClicked = () => {
    !isEditing && props.create && props.create(newRowData);
    isEditing && props.update && props.update(isEditing, newRowData);
  };
  const deletee = (id) => props.deletee && props.deletee(id);
  const getDate = (value = new Date()) => {
    if (typeof value === "string") value = new Date(value);
    const getMonthInt = value.getMonth() + 1;
    const month = getMonthInt < 10 ? `0${getMonthInt}` : getMonthInt;
    const date = value.getDate() < 10 ? `0${value.getDate()}` : value.getDate();
    return `${value.getFullYear()}-${month}-${date}`;
  };
  const getTime = (value = new Date()) => {
    return moment(value).format("HH:mm");
  };
  const setDate = (key, value) => {
    const newData = { ...newRowData };
    const newDate = new Date(newData[key]);
    const splitValue = value.split("-");
    newDate.setFullYear(parseInt(splitValue[0]));
    newDate.setMonth(parseInt(splitValue[1]) - 1);
    newDate.setDate(parseInt(splitValue[2]));
    newData[key] = newDate;
    setNewRowData(newData);
  };
  const setTime = (key, value) => {
    const newData = { ...newRowData };
    const newDate = newData[key] ? newData[key] : new Date();
    const splitValue = value.split(":");
    newDate.setHours(+splitValue[0]);
    newDate.setMinutes(+splitValue[1]);
    newData[key] = newDate;
    setNewRowData(newData);
  };
  return (
    <Col>
      <Row>
        <Form>
          <FormGroup>
            <Label>
              <h3>
                {type} {!isEditing ? "Ekle" : "Güncelle"}
              </h3>
            </Label>
          </FormGroup>
          {cols
            .filter((item) => {
              if (item.noShowForm) return false;
              if (item.noShowEdit && isEditing) return false;
              return true;
            })
            .map((item, key) => (
              <FormGroup key={key}>
                <Label for={item.label}>{item.label}</Label>
                {(item.type === "text" ||
                  item.type === "password" ||
                  item.type === "number") && (
                  <Input
                    type={item.type}
                    value={newRowData[item.name] || ""}
                    id={item.label}
                    onChange={(e) => {
                      updateNewRow(
                        item.name,
                        item.type === "number"
                          ? parseInt(e.target.value)
                          : e.target.value
                      );
                    }}
                  />
                )}
                {item.type === "floatNumber" && (
                  <Input
                    type="number"
                    value={newRowData[item.name] || ""}
                    id={item.label}
                    step={item.step}
                    onChange={(e) =>
                      updateNewRow(item.name, parseFloat(e.target.value))
                    }
                  />
                )}
                {item.type === "select" && (
                  <Input
                    value={newRowData[item.value] || ""}
                    type="select"
                    id={item.label}
                    onChange={(e) => {
                      updateNewRow(
                        item.value,
                        item.varType === "number"
                          ? +e.target.value
                          : e.target.value
                      );
                      item.onChange && item.onChange(e.target.value);
                    }}
                  >
                    <option value="0">Seçiniz</option>
                    {item.values.map((item2, key2) => (
                      <option key={key2} value={item2.id}>
                        {item2.label}
                      </option>
                    ))}
                  </Input>
                )}
                {item.type === "checkbox" && (
                  <Input
                    type="checkbox"
                    checked={newRowData[item.name] || false}
                    id={item.label}
                    onChange={(e) => updateNewRow(item.name, e.target.checked)}
                  />
                )}
                {item.type === "file" && (
                  <React.Fragment>
                    {newRowData[item.name] && (
                      <React.Fragment>
                        <img
                          src={
                            newRowData[item.name] +
                            (newRowData[item.name] &&
                            !newRowData[item.name].startsWith("data")
                              ? "?v=" + Date.now()
                              : "")
                          }
                          style={{ width: "40px", height: "40px" }}
                          alt="veri resmi"
                        />
                        <br />
                      </React.Fragment>
                    )}
                    <Input
                      type="file"
                      id={item.label}
                      key={Date.now()}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.addEventListener(
                          "load",
                          () => {
                            updateNewRow(item.name, reader.result);
                          },
                          false
                        );
                        if (file) {
                          reader.readAsDataURL(file);
                        }
                      }}
                    />
                  </React.Fragment>
                )}
                {item.type === "richText" && (
                  <React.Fragment>
                    <CKEditor
                      id={item.label}
                      editor={ClassicEditor}
                      data={newRowData[item.name] || ""}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      onInit={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        // console.log("Editor is ready to use!", editor);
                        editor.editing.view.change((writer) => {
                          writer.setStyle(
                            "height",
                            "600px",
                            editor.editing.view.document.getRoot()
                          );
                        });
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        updateNewRow(item.name, data);
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />
                  </React.Fragment>
                )}
                {item.type === "specialRender" &&
                  item.specialRender(updateNewRow, newRowData[item.name])}
                {item.type === "date" && (
                  <Row>
                    <Col md="3">
                      Tarih :{" "}
                      <Input
                        type="date"
                        value={getDate(newRowData[item.name])}
                        onChange={(e) => setDate(item.name, e.target.value)}
                      />
                    </Col>
                    <Col md="3">
                      Saat :{" "}
                      <Input
                        type="time"
                        value={getTime(newRowData[item.name])}
                        onChange={(e) => setTime(item.name, e.target.value)}
                      />
                    </Col>
                  </Row>
                )}
              </FormGroup>
            ))}
          <Button onClick={async () => buttonClicked()}>
            {!isEditing ? "Kaydet" : "Güncelle"}
          </Button>
        </Form>
      </Row>
      <Row>
        <h3>{type} Listesi</h3>
      </Row>
      {rows.length === 0 && (
        <React.Fragment>
          <br />
          Veri Bulunamadı
        </React.Fragment>
      )}
      {rows.length > 0 && (
        <Col md={12}>
          <Table>
            <thead>
              <tr>
                {cols
                  .filter((item) => !item.noShow)
                  .map((item, key) => (
                    <td key={key}>{item.label}</td>
                  ))}
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {rows.map((item, key) => (
                <tr
                  key={key}
                  style={{
                    background: item.background ? item.background : "white",
                  }}
                >
                  {cols
                    .filter((item2) => !item2.noShow)
                    .map((item2, key2) => (
                      <td key={key2}>
                        <React.Fragment>
                          {(item2.type === "boolean" ||
                            item2.type === "checkbox") &&
                          !item2.callback
                            ? item[item2.name]
                              ? "TRUE"
                              : "FALSE"
                            : ""}
                          {(item2.type === "boolean" ||
                            item2.type === "checkbox") &&
                          item2.callback
                            ? item2.callback(item[item2.name])
                            : ""}
                          {item2.type !== "boolean" &&
                          item2.type !== "checkbox" &&
                          item2.type !== "file" &&
                          item2.type !== "date" &&
                          !item2.callback
                            ? item[item2.name]
                            : ""}
                          {item2.type !== "boolean" &&
                          item2.type !== "checkbox" &&
                          item2.type !== "file" &&
                          item2.type !== "date" &&
                          item2.callback
                            ? item2.callback(item[item2.name])
                            : ""}
                          {item2.sign && item2.signAlign !== "start"
                            ? item2.sign
                            : ""}
                          {item2.type === "file" &&
                            item2.fileType === "image" &&
                            item[item2.name] && (
                              <img
                                src={item[item2.name] + "?v=" + Date.now()}
                                style={{
                                  width: item2.width,
                                  height: item2.height,
                                }}
                                alt="veri resmi"
                              />
                            )}
                          {item2.type === "date"
                            ? moment(item[item2.name]).format(
                                "yyyy-MM-DD HH:mm"
                              )
                            : ""}
                          {item2.sign && item2.signAlign === "start"
                            ? item2.sign
                            : ""}
                        </React.Fragment>
                      </td>
                    ))}
                  <td>
                    <Button
                      color="success"
                      onClick={() => {
                        setIsEditing(item.Id);
                        setNewRowData(item);
                        const keys = Object.keys(item);
                        for (let i = 0; i < keys.length; i++) {
                          const findCol = cols.find(
                            (item2) => item2.name === keys[i]
                          );
                          if (findCol && findCol.onChange && item[keys[i]]) {
                            findCol.onChange(item[keys[i]]);
                          }
                        }
                      }}
                    >
                      Güncelle
                    </Button>
                  </td>
                  <td>
                    <Button color="danger" onClick={() => deletee(item.Id)}>
                      Sil
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      )}
    </Col>
  );
}
export default AddAndEditTable;
