import React, { useEffect, useState } from "react";
import Home from "./Pages/Home";
import CompanyCategory from "./Pages/CompanyCategory";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import {
  FaComment,
  FaHamburger,
  FaHome,
  FaSignOutAlt,
  FaTimes,
  FaBars,
} from "react-icons/fa";
import Company from "./Pages/Company";
import CompanyBranch from "./Pages/CompanyBranch";
import { eventEmitter, userInfo } from "./utils";
import CompanyProductCategory from "./Pages/CompanyProductCategory";
import CompanyProduct from "./Pages/CompanyProduct";
import CompanyBranchOrder from "./Pages/CompanyBranchOrder";
import api from "./api";
import PromotionCode from "./Pages/PromotionCode";
import Campaign from "./Pages/Campaign";
import CancelOrder from "./Pages/CancelOrder";
import ApplicationRequests from "./Pages/ApplicationRequests";
import CompanyDefaultProductCategory from "./Pages/CompanyDefaultProductCategory";
import TutorialVideos from "./Pages/TutorialVideos";
import TutorialVideosCompanyAndBranch from "./Pages/TutorialVideosCompanyAndBranch";
import ProductRequests from "./Pages/ProductRequests";
import Interaction from "./Pages/Interaction";
import LoadXml from "./Pages/LoadXml";
import Invoices from "./Pages/Invoices";
import CompanyProductKitchenCategory from "./Pages/CompanyProductKitchenCategory";
import CompanyProductkitchenCategoryMatcher from "./Pages/CompanyProductkitchenCategoryMatcher";
import OpeninBanner from "./Pages/OpeningBanner";
import AllOrders from "./Pages/AllOrders";

function Main() {
  const [activePage, setActivePage] = useState("");
  useEffect(() => {
    setActivePage(leftMenu2[0].page);
  }, []);
  const [compainesWithBranches, setCompaniesWithBranches] = useState([]);
  const [compainesWithBranchesForCompany, setCompainesWithBranchesForCompany] =
    useState([]);
  const user = userInfo();
  useEffect(() => {
    if (user.type === 1) {
      const fetchData = async () => {
        const companiesWithBranches = await api.admin.companiesWithBranches();
        const array = [];
        for (const branch of companiesWithBranches) {
          const exists = array.find(
            (item) => item.CompanyId === branch.CompanyId
          );
          if (!exists) {
            const newItem = {
              CompanyId: branch.CompanyId,
              title: branch.CompanyName ? branch.CompanyName : "Firma Yok",
              id: "8-" + branch.CompanyId,
              icon: <FaComment />,
              types: [1],
              children: [
                {
                  title: "Firma İstatistikleri",
                  id: "8-" + branch.CompanyId + "-0",
                  icon: <FaComment />,
                  pageType: "home",
                  props: { companyId: branch.CompanyId },
                  page: (
                    <Home
                      key={"8-" + branch.CompanyId + "-0"}
                      companyId={branch.CompanyId}
                    />
                  ),
                  types: [1],
                },
                {
                  title: "Bayi : " + branch.BranchName,
                  id: "8-" + branch.CompanyId + "-" + branch.CompanyBranchId,
                  icon: <FaComment />,
                  pageType: "home",
                  props: {
                    companyId: branch.CompanyId,
                    companyBranchId: branch.BranchId,
                  },
                  page: (
                    <Home
                      key={
                        "8-" + branch.CompanyId + "-" + branch.CompanyBranchId
                      }
                      companyId={branch.CompanyId}
                      companyBranchId={branch.BranchId}
                    />
                  ),
                  types: [1],
                },
              ],
            };
            array.push(newItem);
          } else {
            const newBranch = {
              title: "Bayi : " + branch.BranchName,
              id: "8-" + branch.CompanyId + "-" + branch.CompanyBranchId,
              icon: <FaComment />,
              pageType: "home",
              props: {
                companyId: branch.CompanyId,
                companyBranchId: branch.BranchId,
              },
              page: (
                <Home
                  key={"8-" + branch.CompanyId + "-" + branch.CompanyBranchId}
                  companyId={branch.CompanyId}
                  companyBranchId={branch.BranchId}
                />
              ),
              types: [1],
            };
            exists.children.push(newBranch);
          }
        }
        setCompaniesWithBranches(array);
      };
      fetchData();
    } else if (user.type === 2) {
      const fetchData = async () => {
        const branches = await api.company.branches();
        const array = [
          {
            title: "Firma İstatistikleri",
            icon: <FaComment />,
            id: "9-0",
            types: [2],
            props: {},
            page: <Home companyId={user.CompanyId} />,
            pageType: "home",
          },
        ];
        for (const branch of branches) {
          array.push({
            title: "Bayi : " + branch.BranchName,
            icon: <FaComment />,
            id: "9-" + branch.Id,
            pageType: "home",
            props: {
              companyBranchId: branch.Id,
            },
            page: <Home companyBranchId={branch.Id} />,
            types: [2],
          });
        }
        setCompainesWithBranchesForCompany(array);
      };
      fetchData();
    }
    window.addEventListener("resize", () => {
      setWidthHeight({ width: window.innerWidth, height: window.innerHeight });
    });
  }, []);
  const dashboardProps = {};
  if (user.type === 2) dashboardProps.companyId = user.CompanyId;
  const leftMenu2 = [
    {
      title: "Dashboard",
      page: <Home key={"11"} {...dashboardProps} />,
      props: {},
      pageType: "home",
      id: "1",
      icon: <FaHome />,
      types: [1, 2, 3],
    },
    {
      title: "Sipariş İptalleri",
      page: <CancelOrder />,
      id: "10",
      types: [1],
      icon: <FaTimes />,
    },
    {
      title: "Firma Kategorileri",
      page: <CompanyCategory />,
      id: "2",
      icon: <FaHamburger />,
      types: [1],
    },
    {
      title: "Ana Ürün Tipi Kategorileri",
      page: <CompanyProductKitchenCategory key={22} />,
      id: "22",
      icon: <FaHamburger />,
      types: [1],
    },
    {
      title: "Alt Ürün Tipi Kategorileri",
      page: <CompanyProductKitchenCategory key={23} sub />,
      id: "23",
      icon: <FaHamburger />,
      types: [1],
    },
    {
      title: "Ana - Alt Ürün Tipi Eşleştirici",
      page: <CompanyProductkitchenCategoryMatcher key={24} />,
      id: "24",
      icon: <FaHamburger />,
      types: [1],
    },
    {
      title: "Firma Başvuruları",
      page: <ApplicationRequests />,
      id: "12",
      icon: <FaHamburger />,
      types: [1],
    },
    {
      title: "Firmalar",
      page: <Company />,
      id: "3",
      icon: <FaHamburger />,
      types: [1],
    },
    {
      title: "Firma Bayileri",
      page: <CompanyBranch />,
      id: "4",
      icon: <FaHamburger />,
      types: [1],
    },
    {
      title: "Ürün Başvuruları",
      page: <ProductRequests />,
      id: "15",
      icon: <FaHamburger />,
      types: [1],
    },
    {
      title: "Varsayılan Menü Kategorileri",
      page: <CompanyDefaultProductCategory />,
      id: "2",
      icon: <FaHamburger />,
      types: [1],
    },
    {
      title: "Eğitim Videoları ( Admin )",
      page: <TutorialVideos />,
      id: "13",
      icon: <FaHamburger />,
      types: [1],
    },
    {
      title: "Menü Kategorileri",
      page: <CompanyProductCategory />,
      id: "5",
      icon: <FaHamburger />,
      types: [2],
    },
    {
      title: "Ürünler",
      page: <CompanyProduct />,
      id: "6",
      icon: <FaHamburger />,
      types: [2],
    },
    {
      title: "XML'den Ürün",
      page: <LoadXml />,
      id: "20",
      icon: <FaHamburger />,
      types: [2],
    },
    {
      title: "Promosyon Kod",
      page: <PromotionCode />,
      id: "9",
      icon: <FaHamburger />,
      types: [2],
    },
    {
      title: "Siparişler",
      page: <CompanyBranchOrder />,
      id: "7",
      icon: <FaHamburger />,
      types: [3],
    },
    {
      title: "İstatistikler",
      types: [1],
      id: "8",
      icon: <FaHamburger />,
      children: compainesWithBranches,
    },
    {
      title: "İstatistikler",
      types: [2],
      id: "9",
      icon: <FaHamburger />,
      children: compainesWithBranchesForCompany,
    },
    {
      title: "Etkileşim",
      types: [1],
      id: "16",
      icon: <FaHamburger />,
      children: [
        {
          title: "Kampanyalar",
          page: <Campaign />,
          id: "10",
          icon: <FaHamburger />,
          types: [1],
        },
        {
          title: "Sms",
          types: [1],
          id: "17",
          icon: <FaHamburger />,
          page: <Interaction type="sms" key="sms" />,
        },
        {
          title: "Bildirim",
          types: [1],
          id: "18",
          icon: <FaHamburger />,
          page: (
            <Interaction type="push-notification" key="push-notification" />
          ),
        },
        {
          title: "Landing Banner",
          types: [1],
          id: "25",
          icon: <FaHamburger />,
          page: <OpeninBanner />,
        },
      ],
    },
    {
      title: "Faturalar",
      types: [1],
      id: "21",
      icon: <FaHamburger />,
      page: <Invoices />,
    },
    {
      title: "Tüm Siparişler",
      types: [1],
      id: "26",
      icon: <FaHamburger />,
      page: <AllOrders />,
    },
    {
      title: "Eğitim Videoları",
      page: <TutorialVideosCompanyAndBranch />,
      id: "14",
      icon: <FaHamburger />,
      types: [1, 2, 3],
    },
    // {
    //   title: "İstatistikler",
    //   types: [3],
    //   id: "10",
    //   page: <Home />,
    //   icon: <FaHamburger />,
    // },
  ];
  const [widthHeight, setWidthHeight] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  return (
    <div className="d-flex position-relative h-100">
      <ProSidebar
        toggled={toggled}
        onToggle={handleToggleSidebar}
        breakPoint="md"
      >
        <Menu iconShape="square">
          {<GetMenuItems items={leftMenu2} setActivePage={setActivePage} />}
          <MenuItem
            icon={<FaSignOutAlt />}
            onClick={() => {
              localStorage.removeItem("token");
              eventEmitter.emit("logined", false);
            }}
          >
            Çıkış
          </MenuItem>
        </Menu>
      </ProSidebar>
      <div className="d-flex flex-column flex-grow-1 p-4">
        <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
          <FaBars />
        </div>
        {activePage}
      </div>
    </div>
  );
}
const GetMenuItems = ({ items, setActivePage }) => {
  items = items || [];
  return items
    .filter((item) => item.types.includes(userInfo().type))
    .map((item, key) => {
      if (!item.children) {
        return (
          <MenuItem
            icon={item.icon}
            key={key}
            onClick={() => {
              item.pageType !== "home" && item.page && setActivePage(item.page);
              item.pageType === "home" &&
                setActivePage(<Home {...item.props} />);
            }}
            title={item.title}
          >
            {item.title}
          </MenuItem>
        );
      } else {
        if (!Array.isArray(item.children)) {
          return <React.Fragment key={key} />;
        }
        return (
          <SubMenu title={item.title} key={key} icon={item.icon}>
            {
              <GetMenuItems
                items={item.children}
                setActivePage={setActivePage}
              />
            }
          </SubMenu>
        );
      }
    });
};
export default Main;
