import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import api from "../api";
const TutorialVideosCompanyAndBranch = () => {
  const [tutorialVideos, setTutorialVideos] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const tutorialVideos = await api.public.tutorialVideos.get();
      !tutorialVideos.error && setTutorialVideos(tutorialVideos);
    };
    fetchData();
  }, []);
  return (
    <Col>
      {tutorialVideos
        .map((item) => {
          const idParse = item.Url.split("watch?v=");
          const Url = "https://www.youtube.com/embed/" + idParse[1];
          return { ...item, Url };
        })
        .map((item, key) => (
          <Col
            key={key}
            style={{ padding: "10px", borderBottom: "1px solid gray" }}
          >
            <Row>
              <h2>{item.Title}</h2>
            </Row>
            <Row style={{ width: "560px", height: "315px" }}>
              <iframe
                width="560"
                height="315"
                src={item.Url}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Row>
            <Row>
              <p dangerouslySetInnerHTML={{ __html: item.Description }}></p>
            </Row>
          </Col>
        ))}
    </Col>
  );
};
export default TutorialVideosCompanyAndBranch;
