import EventEmitter from "events";
import moment from "moment";
import api from "./api";
const eventEmitter = new EventEmitter();
const isLogin = async () => {
  const token = localStorage.getItem("token");
  if (!token || token === null) return false;
  const user = userInfo();
  if (!user) return false;
  let controlFromApi;
  if (user.type === 1) controlFromApi = await api.admin.loginWithToken(token);
  else if (user.type === 2)
    controlFromApi = await api.company.loginWithToken(token);
  else if (user.type === 3)
    controlFromApi = await api.companyBranch.loginWithToken(token);
  if (controlFromApi && controlFromApi.token) {
    localStorage.setItem("token", controlFromApi.token);
    return true;
  }
  return false;
};
const userInfo = () => {
  const token = localStorage.getItem("token");
  if (!token) return undefined;
  const parse = token.split(".");
  const data = parse[1];
  const base64decoded = atob(data);
  const json = JSON.parse(base64decoded);
  return json;
};
const getToken = () => localStorage.getItem("token");
const getDate2FromMoment = (date) => {
  const datetime2 = moment(date).format("YYYY-MM-DD HH:mm:ss.SSS");
  return datetime2;
};
export { isLogin, eventEmitter, userInfo, getToken, getDate2FromMoment };
