import { useEffect, useState } from "react";
import api, { apiUrl } from "../api";
import AddAndEditTable from "../Components/AddAndEditTable";
const CompanyProductCategory = () => {
  const [companyProductCategories, setCompanyProductCategories] = useState([]);
  useEffect(() => {
    getCompanyProductCategoriesList();
  }, []);
  const getCompanyProductCategoriesList = async () => {
    const companyProductCategories =
      await api.company.companyProductCategory.get();
    !companyProductCategories.error &&
      setCompanyProductCategories(
        companyProductCategories.map((item) => ({
          ...item,
          ImageUrl:
            apiUrl + "/static/companyProductCategoryImages/" + item.Id + ".jpg",
        }))
      );
  };
  const createCompanyProductCategory = async (row) => {
    const create = await api.company.companyProductCategory.create(row);
    if (!create.error) {
      getCompanyProductCategoriesList();
      return alert("Kategori Oluşturuldu.");
    }
    alert(
      "Kategori oluşturulamadı.(Hatalar :" +
        create.errors?.map((item) => item.msg).join(",") +
        ")"
    );
  };
  const updateCompanyProductCategory = async (id, row) => {
    delete row.subs;
    const update = await api.company.companyProductCategory.update(id, row);
    if (!update.error) {
      getCompanyProductCategoriesList();
      return alert("Kategori Güncellendi.");
    }
    alert(
      "Kategori Güncellenemedi.(Hatalar :" +
        update.errors?.map((item) => item.msg).join(",") +
        ")"
    );
  };
  const deleteCompanyProductCategory = async (id) => {
    const confirmm = window.confirm("Silmek istediğinize emin misiniz?");
    if (confirmm) {
      const del = await api.company.companyProductCategory.delete(id);
      if (!del.error) {
        getCompanyProductCategoriesList();
        return alert("Kategori Silindi.");
      }
      alert("Kategori Silinemedi.");
    }
  };
  return (
    <AddAndEditTable
      type="Ürün Menü Kategorileri"
      rows={companyProductCategories}
      cols={[
        {
          name: "Name",
          type: "text",
          label: "Kategori Adı",
        },
        {
          name: "Description",
          type: "text",
          label: "Kategori Açıklaması",
        },
        {
          name: "ImageUrl",
          type: "file",
          label: "Kategori Resmi",
          fileType: "image",
          width: "40px",
          height: "40px",
        },
      ]}
      create={(row) => createCompanyProductCategory(row)}
      update={(id, row) => updateCompanyProductCategory(id, row)}
      deletee={(id) => deleteCompanyProductCategory(id)}
    />
  );
};
export default CompanyProductCategory;
