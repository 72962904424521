import { useEffect, useState } from "react";
import api, { apiUrl } from "../api";
import AddAndEditTable from "../Components/AddAndEditTable";
const CompanyDefaultProductCategory = () => {
  const [companyProductCategories, setCompanyProductCategories] = useState([]);
  const [companyCategories, setCompanyCategories] = useState([]);
  useEffect(() => {
    getCompanyProductCategoriesList();
    getCompanyCategories();
  }, []);
  const getCompanyProductCategoriesList = async () => {
    const companyProductCategories =
      await api.admin.companyDefaultProductCategory.get();
    !companyProductCategories.error &&
      setCompanyProductCategories(
        companyProductCategories.map((item) => ({
          ...item,
          CompanyCategory: item["CompanyCategory.Name"],
          ImageUrl:
            apiUrl +
            "/static/defaultCompanyProductCategoryImages/" +
            item.Id +
            ".jpg",
        }))
      );
  };
  const getCompanyCategories = async () => {
    const categories = await api.admin.companyCategory.get();
    !categories.error && setCompanyCategories(categories);
  };
  const createCompanyProductCategory = async (row) => {
    const create = await api.admin.companyDefaultProductCategory.create(row);
    if (!create.error) {
      getCompanyProductCategoriesList();
      return alert("Kategori Oluşturuldu.");
    }
    alert(
      "Kategori oluşturulamadı.(Hatalar :" +
        create.errors?.map((item) => item.msg).join(",") +
        ")"
    );
  };
  const updateCompanyProductCategory = async (id, row) => {
    delete row.subs;
    const update = await api.admin.companyDefaultProductCategory.update(
      id,
      row
    );
    if (!update.error) {
      getCompanyProductCategoriesList();
      return alert("Kategori Güncellendi.");
    }
    alert(
      "Kategori Güncellenemedi.(Hatalar :" +
        update.errors?.map((item) => item.msg).join(",") +
        ")"
    );
  };
  const deleteCompanyProductCategory = async (id) => {
    const confirmm = window.confirm("Silmek istediğinize emin misiniz?");
    if (confirmm) {
      const del = await api.admin.companyDefaultProductCategory.delete(id);
      if (!del.error) {
        getCompanyProductCategoriesList();
        return alert("Kategori Silindi.");
      }
      alert("Kategori Silinemedi.");
    }
  };
  return (
    <AddAndEditTable
      type="Ürün Menü Kategorileri"
      rows={companyProductCategories}
      cols={[
        {
          name: "Name",
          type: "text",
          label: "Kategori Adı",
        },
        {
          name: "Description",
          type: "text",
          label: "Kategori Açıklaması",
        },
        {
          type: "select",
          name: "CompanyCategoryId",
          label: "Firma Kategorisi",
          value: "CompanyCategoryId",
          varType: "number",
          values: companyCategories.map((item) => ({
            ...item,
            label: item.Name,
            id: item.Id,
          })),
          noShow: true,
        },
        {
          type: "text",
          name: "CompanyCategory",
          label: "Firma Kategorisi",
          noShowForm: true,
          noShowEdit: true,
        },
        {
          name: "ImageUrl",
          type: "file",
          label: "Kategori Resmi",
          fileType: "image",
          width: "40px",
          height: "40px",
        },
      ]}
      create={(row) => createCompanyProductCategory(row)}
      update={(id, row) => updateCompanyProductCategory(id, row)}
      deletee={(id) => deleteCompanyProductCategory(id)}
    />
  );
};
export default CompanyDefaultProductCategory;
