import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import api from "../api";

const CancelOrder = () => {
  const [manualCode, setManualCode] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(undefined);
  const [cancelDescription, setCancelDescription] = useState("");
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const search = async () => {
    const find = await api.admin.getOrderByManualCode(manualCode);
    if (!find.error) {
      setSelectedOrder(find);
    } else alert("Sipariş bulunamadı");
  };
  const cancelOrder = async () => {
    const cancel = await api.admin.cancelOrder(
      selectedOrder.Id,
      cancelDescription
    );
    if (!cancel.error) {
      await search();
      getCancelledOrders();
      return alert("Sipariş başarı ile iptal edildi");
    }
    alert(
      "Sipariş iptal edilemedi(Hatalar :" +
        cancel.errors?.map((item) => item.msg).join(",") +
        ")"
    );
  };
  useEffect(() => {
    getCancelledOrders();
  }, []);
  const getCancelledOrders = async () => {
    const cancelledOrders = await api.admin.getCancelledOrders();
    setCancelledOrders(cancelledOrders);
  };
  return (
    <Col>
      <Modal isOpen={selectedOrder ? true : false}>
        <ModalHeader>Sipariş Detayları</ModalHeader>
        <ModalBody>
          <Col>
            <Row>
              {selectedOrder && (
                <Table>
                  <thead>
                    <tr>
                      <td>Şirket</td>
                      <td>Bayi</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{selectedOrder?.Company?.Name}</td>
                      <td>
                        {selectedOrder?.CompanyBranch
                          ? selectedOrder?.CompanyBranch?.BranchName
                          : "Bayi farketmez seçilmiş"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </Row>
            <Row>
              {selectedOrder && (
                <Table>
                  <thead>
                    <tr>
                      <td>Ürün - Seçenek</td>
                      <td>Adet</td>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedOrder?.CompanyBranchOrderProducts.map(
                      (item, key) => (
                        <tr key={key}>
                          <td>
                            {item.CompanyProductOption.CompanyProduct.Name} -{" "}
                            {item.CompanyProductOption.Name}
                          </td>
                          <td>{item.Number}</td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td>Durum</td>
                      <td>
                        {selectedOrder.Status === 1
                          ? "Bekleniyor"
                          : selectedOrder.Status === 2
                          ? "Alındı"
                          : selectedOrder.Status === 3
                          ? "İptal edildi"
                          : "Süresi Geçmiş"}
                      </td>
                    </tr>
                    <tr>
                      <td>Kimden</td>
                      <td>{selectedOrder.Sender.NameSurname}</td>
                    </tr>
                    <tr>
                      <td>Kime</td>
                      <td>
                        {selectedOrder.Receiver
                          ? selectedOrder.Receiver.NameSurname
                          : selectedOrder.ReceiverPhoneNumber}
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">İptal sebebi</td>
                      <td>
                        <Input
                          type="textarea"
                          rows="5"
                          value={cancelDescription}
                          onChange={(e) => setCancelDescription(e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </Row>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={cancelOrder}
            disabled={selectedOrder?.Status !== 3 ? false : true}
          >
            Sipraişi İptal Et
          </Button>
          <Button
            color="danger"
            onClick={() => setSelectedOrder() && setCancelDescription()}
          >
            Kapat
          </Button>
        </ModalFooter>
      </Modal>
      <Row>Ürün Ara</Row>
      <Row>
        Manuel Kod :{" "}
        <Input
          type="text"
          value={manualCode}
          onChange={(e) => setManualCode(e.target.value)}
        />
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Button color="success" onClick={search}>
          Ara
        </Button>
      </Row>
      <Row>
        <Table>
          <thead>
            <tr>
              <td>Id</td>
              <td>Kimden</td>
              <td>Kime</td>
              <td>Ürünler</td>
              <td>İptal sebebi</td>
              <td>İptal tarihi</td>
            </tr>
          </thead>
          <tbody>
            {cancelledOrders.map((item, key) => (
              <tr key={key}>
                <td>{item.Id}</td>
                <td>{item.Sender.NameSurname}</td>
                <td>
                  {item.Receiver
                    ? item.Receiver.NameSurname
                    : item.ReceiverPhoneNumber}
                </td>
                <td>
                  {item?.CompanyBranchOrderProducts.map((item2, key2) => (
                    <React.Fragment key={key2}>
                      {item2.CompanyProductOption.CompanyProduct.Name} -{" "}
                      {item2.CompanyProductOption.Name} -{item2.Number} Adet
                      <br />
                    </React.Fragment>
                  ))}
                </td>
                <td>{item.CancelDescription}</td>
                <td>{item.updatedAt}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </Col>
  );
};

export default CancelOrder;
