import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row, Input, Button, Table } from 'reactstrap';
import api from '../api';
const Interaction = ({ type }) => {
	const TypeStr = type === 'sms' ? 'Sms' : 'Bildirim';

	const [message, setMessage] = useState('');
	const [messages, setMessages] = useState([]);
	useEffect(() => getMessages(), []);

	const getMessages = async () => {
		const messages = await api.admin.interaction.get(type);
		setMessages(messages);
	};
	const send = async () => {
		const create = await api.admin.interaction.send({
			Type: type,
			Value: message,
		});
		if (!create.error) {
			alert(TypeStr + ' başarıyla gönderildi');
			getMessages();
			setMessage('');
		} else alert("Bir hata oluştu.(Hatalar :"+create.errors?.map(item => item.msg).join(",")+")");
	};
	return (
		<Col>
			<Row>
				<h4>{TypeStr}</h4>
			</Row>
			<Row>
				<h3>{TypeStr} Ekle</h3>
			</Row>
			<Row>
				<Input
					type="text"
					onChange={(e) => setMessage(e.target.value)}
					value={message}
				/>
			</Row>
			<Row>
				<Button color="success" onClick={send}>
					Gönder
				</Button>
			</Row>
			<Row>
				<h3>{TypeStr} Listesi</h3>
			</Row>
			<Row>
				<Table>
					<thead>
						<tr>
							<td>Id</td>
							<td>Mesaj</td>
							<td>Gönderim Tarihi</td>
						</tr>
					</thead>
					<tbody>
						{messages.map((item, key) => (
							<tr key={key}>
								<td>{item.Id}</td>
								<td>{item.Value}</td>
								<td>{moment(item.createdAt).format()}</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Row>
		</Col>
	);
};
export default Interaction;
