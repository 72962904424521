import { useEffect, useState } from "react";
import api, { apiUrl } from "../api";
import AddAndEditTable from "../Components/AddAndEditTable";
const OpeninBanner = () => {
  const [campaigns, setCampaigns] = useState([]);
  useEffect(() => {
    getCampaignList();
  }, []);
  const getCampaignList = async () => {
    const campaigns = await api.admin.openingBanner.get();
    !campaigns.error &&
      setCampaigns(
        campaigns.map((item) => ({
          ...item,
          LogoImageUrl:
            apiUrl + "/static/openingBannerImages/" + item.Id + ".jpg",
        }))
      );
  };
  const createCampaign = async (row) => {
    const create = await api.admin.openingBanner.create(row);
    if (!create.error) {
      getCampaignList();
      return alert("Banner oluşturuldu.");
    }
    alert(
      "Banner oluşturulamadı.(Hatalar :" +
        create.errors?.map((item) => item.msg).join(",") +
        ")"
    );
  };
  const updateCampaign = async (id, row) => {
    const update = await api.admin.openingBanner.update(id, row);
    if (!update.error) {
      getCampaignList();
      return alert("Banner Güncellendi.");
    }
    alert(
      "Banner Güncellenemedi. (Hatalar :" +
        update.errors?.map((item) => item.msg).join(",") +
        ")"
    );
  };
  const deleteCampaign = async (id) => {
    const confirmm = window.confirm("Silmek istediğinize emin misiniz?");
    if (confirmm) {
      const del = await api.admin.openingBanner.delete(id);
      if (!del.error) {
        getCampaignList();
        return alert("Banner Silindi.");
      }
      alert(
        "Banner Silinemedi.(Hatalar :" +
          del.errors?.map((item) => item.msg).join(",") +
          ")"
      );
    }
  };
  return (
    <AddAndEditTable
      type="Banner"
      rows={campaigns}
      cols={[
        {
          name: "Title",
          type: "text",
          label: "Başlık",
        },
        {
          name: "LogoImageUrl",
          type: "file",
          label: "Banner Resmi",
          fileType: "image",
          width: "40px",
          height: "40px",
        },
      ]}
      create={(row) => createCampaign(row)}
      update={(id, row) => updateCampaign(id, row)}
      deletee={(id) => deleteCampaign(id)}
    />
  );
};
export default OpeninBanner;
