import moment from "moment";
import { createRef, useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import QrReader from "react-qr-reader";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import api from "../api";
import { FaArrowDown, FaCalendarAlt } from "react-icons/fa";

const CompanyBranchOrder = () => {
  const [orders, setOrders] = useState([]);
  const [startDate, setStartDate] = useState(moment().set("D", 1));
  const [endDate, setEndDate] = useState(moment());
  const [selectedOrderType, setSelectedOrderType] = useState("0");
  const [manualCode, setManualCode] = useState("");
  useEffect(() => {
    getOrders();
  }, []);
  const getOrders = async () => {
    const params = {
      startDate: startDate.format(),
      endDate: endDate.format(),
    };
    if (parseInt(selectedOrderType) > 0) params.status = selectedOrderType;
    const orders = await api.companyBranch.orders(params);
    setOrders(orders);
  };
  const handleError = (err) => {
    setResult("Barkod okunurken hata oluştu" + err);
  };
  const handleScan = async (data) => {
    if (data) {
      setResult("QR Okundu, kontrol ediliyor");
      const getOrder = await api.companyBranch.getOrder(data);
      if (getOrder.error === 20) {
        return alert("Bu ürün bu bayiye ait değil veya süresi geçmiş");
      }
      if (getOrder.error === 25) {
        return alert("Bu ürün zaten alınmış");
      }
      if (getOrder.error) return alert(getOrder.error);
      setModalIsOpen(true);
      setJwtSignedToken(data);
      setManualCode();
      setSelectedOrder({ OrderProducts: getOrder.products });
    }
  };
  const handleManualCode = async () => {
    if (manualCode) {
      const getOrder = await api.companyBranch.getOrderByManualCode(manualCode);
      if (getOrder.error === 20) {
        return alert("Bu ürün bu bayiye ait değil veya süresi geçmiş");
      }
      if (getOrder.error === 25) {
        return alert("Bu ürün zaten alınmış");
      }
      if (getOrder.error) {
        return alert(getOrder.error);
      }
      setModalIsOpen(true);
      setJwtSignedToken();

      setSelectedOrder({ OrderProducts: getOrder.products });
    }
  };
  const [result, setResult] = useState("");
  const [jwtSignedToken, setJwtSignedToken] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  useEffect(() => {
    setResult("");
    setJwtSignedToken("");
  }, [modalIsOpen]);
  const [selectedOrder, setSelectedOrder] = useState();
  const giveOrder = async ({ jwtSignedToken, manualCode }) => {
    let giveOrder;
    if (jwtSignedToken)
      giveOrder = await api.companyBranch.giveOrder(jwtSignedToken);
    else if (manualCode)
      giveOrder = await api.companyBranch.giveOrderByManualCode(manualCode);
    else return alert("Ürün QR veya manuel kod bulunmuyor");
    if (giveOrder.error === 20) {
      return alert("Bu ürün bu bayiye ait değil veya süresi geçmiş");
    }
    if (giveOrder.error === 25) {
      return alert("Bu ürün zaten alınmış");
    }
    getOrders();
    alert("Ürün başarıyla verildi.");
  };
  const selectRef = useRef();
  return (
    <Col>
      <Modal isOpen={modalIsOpen}>
        <ModalHeader>Sipariş Detayları</ModalHeader>
        <ModalBody>
          <Col>
            <Row>
              <Table>
                <thead>
                  <tr>
                    <td>Ürün - Seçenek</td>
                    <td>Adet</td>
                  </tr>
                </thead>
                <tbody>
                  {selectedOrder &&
                    selectedOrder.OrderProducts.map((item, key) => (
                      <tr key={key}>
                        <td>
                          {item.ProductName} - {item.ProductOptionName}
                        </td>
                        <td>{item.Number}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Row>
            <Row>
              {(jwtSignedToken || manualCode) && (
                <Button
                  color="success"
                  onClick={() => {
                    giveOrder({ jwtSignedToken, manualCode });
                  }}
                >
                  Verildi Olarak İşaretle
                </Button>
              )}
            </Row>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => setModalIsOpen(false)}>
            Kapat
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Row>
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: "200px", height: "200px" }}
          />
        </Row>
        <Row>
          <Col md={2}>Veya Manuel Kod ile :</Col>
          <Col md={2}>
            <Input
              type="text"
              value={manualCode}
              onChange={(e) => setManualCode(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <Button onClick={handleManualCode}>Siparişi getir</Button>
          </Col>
        </Row>
        <Row>
          <p>{result}</p>
        </Row>
      </Row>
      <Row>
        <Col md={1}>Başlangıç Tarihi: </Col>
        <Col md={3}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "start",
            }}
          >
            <ReactDatePicker
              locale="tr"
              onChange={(e) => setStartDate(moment(e))}
              selected={startDate?.toDate()}
              id="company-branch-orders-start-date"
              icon={<FaCalendarAlt />}
            />
            <label htmlFor="company-branch-orders-start-date">
              <FaCalendarAlt
                style={{
                  marginTop: "5px",
                  marginLeft: "3px",
                  cursor: "pointer",
                }}
              />
            </label>
          </div>
        </Col>
        <Col md={1}>Bitiş Tarihi: </Col>
        <Col md={3}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "start",
            }}
          >
            <ReactDatePicker
              locale="tr"
              onChange={(e) => setEndDate(moment(e))}
              selected={endDate?.toDate()}
              id="company-branch-orders-end-date"
            />
            <label htmlFor="company-branch-orders-end-date">
              <FaCalendarAlt
                style={{
                  marginTop: "5px",
                  marginLeft: "3px",
                  cursor: "pointer",
                }}
              />
            </label>
          </div>
        </Col>
        <Col md={1}>Sipariş Durumu</Col>
        <Col md={3}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "start",
            }}
          >
            <Input
              type="select"
              value={selectedOrderType}
              onChange={(e) => setSelectedOrderType(e.target.value)}
              id="order-type-input"
              innerRef={selectRef}
            >
              <option value="0">Tamamı</option>
              <option value="1">Bekleniyor</option>
              <option value="2">Alındı</option>
              <option value="3">İptal Edilmiş</option>
              <option value="4">Süresi Geçmiş</option>
            </Input>
            <Label for="order-type-input">
              <FaArrowDown
                style={{
                  marginTop: "5px",
                  marginLeft: "3px",
                  cursor: "pointer",
                }}
              />
            </Label>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Button
          onClick={() => {
            getOrders();
          }}
          color="primary"
        >
          Filtrele
        </Button>
      </Row>
      <Row>
        <Table>
          <thead>
            <tr>
              <td>Id</td>
              <td>Tarih</td>
              <td>Ücret</td>
              <td>Gönderen</td>
              <td>Alan</td>
              <td>Telefon Numarası</td>
              <td>Sms</td>
              <td>Bildirim</td>
              <td>Durum</td>
            </tr>
          </thead>
          <tbody>
            {orders.map((item, key) => {
              return (
                <tr key={key}>
                  <td>{item.Id}</td>
                  <td>{moment(item.Date).format()}</td>
                  <td>{item.CartPrice} TL</td>
                  <td>{item.Sender?.NameSurname}</td>
                  <td>
                    {item.Receiver
                      ? item.Receiver.NameSurname
                      : "Kayıtlı olmayan kullanıcı"}
                  </td>
                  <td>{item.ReceiverPhoneNumber}</td>
                  <td>{item.Sms === 1 ? "Gönderildi" : "Gönderilemedi"}</td>
                  <td>
                    {item.Notification === 1 ? "Gönderildi" : "Gönderilemedi"}
                  </td>
                  <td>
                    {item.Status === 1
                      ? "Bekleniyor"
                      : item.Status === 2
                      ? "Alındı"
                      : item.Status === 3
                      ? "İptal edildi"
                      : "Süresi Geçmiş"}
                  </td>
                  <td>
                    <Button
                      color="success"
                      onClick={() => {
                        setSelectedOrder(item);
                        setModalIsOpen(true);
                      }}
                    >
                      Detaylar
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
    </Col>
  );
};
export default CompanyBranchOrder;
